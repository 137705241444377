import React, { useEffect } from 'react'

import { Login } from './Login'
import { useDispatch } from 'react-redux';
import { Auth } from '../../services/index';
import { LocalStorage } from '../../services/index';
import { setCurrentMenu, setUser } from '../Login/UserSlice';
import { setIdRol, clear } from '../Login/UserSlice';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { validacionVacios } from '../../helpers/validaciones';
import { alerta, alertaSinBoton, alertaTimer } from '../../helpers/alertas';
import { FormService } from '../../services/api/formService';
import Swal from 'sweetalert2';

const LoginContainer = () => {

    const [credentials, setCredentials] = useState({
        Dimension:false,
    })
    const [isOpenRecover, setIsOpenRecover] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)
    const [emailRecover, setEmailRecover] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [Dimension, setDimension] = useState()
    /* const [roles, setRoles] =useState([]) */
    const ls = new LocalStorage();
    


    const recoverPassword = () => {
        setIsOpenRecover(!isOpenRecover)
    }

    const restorePassword = async () => {
        const formService = new FormService()
        try {
            setIsLoading(true)
            const response = await formService.create(`CorreoRecuperacion?correo=${emailRecover}`)
            if (response.respuesta === 'exito') {
                /* console.log("Redirección ejecutada") */
                navigate('/restore-password',{state:{correo:emailRecover}})
            } else {
                alertaSinBoton("Error, correo no registrado", "warning", "center", 3000)
            }
            setIsLoading(false)
        } catch (err) {
            setError(err)
        }
    }

    const onHandleChangeCredentials = (value, name) => {
        setCredentials({
            ...credentials,
            [name]: value
        })
        /* if (name === 'IdRolUsuario') {
            const selectRol = roles.filter(rol => {
                return rol.IdRoles === value
            })
            ls.setItem('rolName', selectRol[0].Descripcion)
        } */
    }

    const onRestorePassword = (value) => {
        setEmailRecover(value)
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        if(validacionVacios(credentials.username, credentials.password, credentials.password)) {
            alerta('Todos los campos son obligatorios', 'warning');
            setIsLoading(false)
            return;
        }
        const _auth = new Auth();
        const data = await _auth.loginUser({
            ...credentials
        });
        const _lStorage = new LocalStorage();

        if(data?.result == 'exito') {
            _lStorage.setAsStringItem('usuario', data )
            _lStorage.setAsStringItem('dimension', data.dimension)            
            _lStorage.setAsStringItem('roles', data.roles )
            _lStorage.setAsStringItem('menu', data.menu )
            _lStorage.setItem('access_token', data.token )
            alertaSinBoton('Bienvenido','success','center',1000)
            setTimeout(() => {
                console.log(data.roles[0].IdRol)
                if(data.roles[0].IdRol == 4){
                    console.log('Estudiante')
                    navigate("/dashboard/estudiante/mis_cursos_activos"); 
                }else{
                    navigate("/dashboard/inicio");
                }
            }, 2600); 
        }else{
            Swal.fire({
                text: data.mensaje,
                icon: 'warning',
                position: 'center',
                showConfirmButton: true,
                timer: 20000,
                customClass: {
                    content: 'custom-text-size'
                }
            });
            setIsLoading(false)
            return
        }

        // setIsLoading(false)
        // dispatch(setUser(data))
        // dispatch(setCurrentMenu(data?.menu))
        // _lStorage.setItem('idRol', data.roles[0].IdRolUsuario)
        // dispatch(setIdRol(data.roles[0].IdRolUsuario))
    };
    
    return (
        <>
            <Login 
                handleSubmit={ handleSubmit }
                onHandleChangeCredentials={ onHandleChangeCredentials }
                formData={ credentials }
                recoverPassword={ recoverPassword }
                isOpenRecover={ isOpenRecover }
                emailRecover={ emailRecover }
                onRestorePassword={ onRestorePassword }
                restorePassword={ restorePassword }
                error={ error }
                isLoading={ isLoading }
                setDimension={setDimension}
            />
            <style jsx>{`
                .custom-text-size{
                    font-size: 20px;
                }
            `}</style>
        </>
    )
}

export { LoginContainer }