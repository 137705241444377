import React, { useState, useEffect } from 'react';
import { FormService } from '../../../services/api/formService';
import { useLocation } from 'react-router-dom';
import RevisionPreguntas from './RevisionPreguntas';
import { Card, Col, Row } from 'react-bootstrap';

const RevisionCuestionario = () => {

    const api=new FormService();
    const [respuestas, setRespuestas] = useState([])
    const [nota, setNota] = useState(0)
    const id= useLocation().state.id

    useEffect(()=>{
        obtenerRespuestas()
    },[])


    const obtenerRespuestas = async () => {
        const response= await api.getAll(`resultadosAtividadesLMS/revisionCuestionario?id=${id}`)
        setRespuestas(response.data.respuestas)
        setNota(response.data.nota)
    }

  return (
    <div>
    <Card>
        <Row>
            <Col>
                <Card.Title>Revision de Cuestionario</Card.Title>
            </Col>
            <Col>
                Nota: {nota} de 100
            </Col>
        </Row>
    </Card>
      {respuestas.length > 0 ? (
        <RevisionPreguntas 
            respuestas={respuestas}
        />
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default RevisionCuestionario;
