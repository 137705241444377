import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import IconoRecursoActividad from './IconoRecursoActividad';
import { useNavigate } from 'react-router-dom';
import { BASE_SERVIDOR } from '../../../services/api/config';
import { IoMdSettings } from 'react-icons/io';
import { AiOutlineDrag } from 'react-icons/ai';

export default function ActividadRecurso({
    actividad,
    estudiante,
    docente,
    eliminar,
    modificarProgreso,
    setModalAR,
    setDataAR,
    setModoActualizar,
    provided,
    IdUsuario,
}) {
    console.log(IdUsuario)
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    
    const codigoVideo = (url) => {
        if (url.includes('youtu.be')) {
            return url.split('youtu.be/')[1];
        }
        if (url.includes('youtube.com')) {
            return url.split('youtube.com/watch?v=')[1];
        }
    }

    function isHTML(content) {
        const doc = new DOMParser().parseFromString(content, 'text/html');
        return Array.from(doc.body.childNodes).some(node => node.nodeType === Node.ELEMENT_NODE);
    }

    function formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) return '';
    
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    

    return (
        <>
            <Card
                style={{
                    boxShadow: 'none',
                    border: '2px solid #ccc',
                    borderRightColor: '#aaa'
                }}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <Row>
                    {estudiante && docente === false && (
                        <div style={{ textAlign: 'right', display: windowWidth > 768 ? 'none' : 'block' }}>
                            {actividad?.progreso_usuario?.visto === 1 ? (
                                <Button variant="success" onClick={() => { modificarProgreso(actividad, 0); }}>Visto</Button>
                            ) : (
                                <Button variant="light" onClick={() => { modificarProgreso(actividad, 1); }}>Marcar Visto</Button>
                            )}
                        </div>
                    )}
                    <Col>
                        <Card.Body>
                            <Card.Title>
                                {!estudiante && (
                                    <span style={{ position: 'absolute', left: 3, top: 3 }} {...provided.dragHandleProps}>
                                        <AiOutlineDrag size={25} />
                                    </span>
                                )}
                                <IconoRecursoActividad id_tipo={actividad?.id_tipo} />
                                {actividad?.nombre}
                            </Card.Title>
                            <p>{actividad?.descripcion}</p>
                        </Card.Body>
                    </Col>
                    {!estudiante && (
                        <Col style={{ textAlign: 'right' }}>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <IoMdSettings />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            const formattedActivity = {
                                                ...actividad,
                                                fecha_inicio: formatDate(actividad.fecha_inicio),
                                                fecha_fin: formatDate(actividad.fecha_fin)
                                            };
                                            setModalAR(true);
                                            setDataAR(formattedActivity);
                                            setModoActualizar(true);
                                        }}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => { eliminar(actividad); }}>Eliminar</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}
                    {estudiante && docente === false && (
                        <Col style={{ textAlign: 'right', display: windowWidth < 768 ? 'none' : 'block' }}>
                            {actividad?.progreso_usuario?.visto === 1 ? (
                                <Button variant="success" onClick={() => { modificarProgreso(actividad, 0); }}>Visto</Button>
                            ) : (
                                <Button variant="light" onClick={() => { modificarProgreso(actividad, 1); }}>Marcar Visto</Button>
                            )}
                        </Col>
                    )}
                </Row>
                {actividad.id_tipo === 1 && (
                    <iframe
                        width="100%"
                        height="480"
                        src={`https://www.youtube.com/embed/${codigoVideo(actividad.url)}`}
                        title="BIENVENIDA CURSO"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                )}
                {actividad.id_tipo === 2 && (
                    <a href={actividad.url} target="_blank" rel="noopener noreferrer">
                        {actividad.url}
                    </a>
                )}
                {actividad.id_tipo === 3 && (
                    <a href={`${BASE_SERVIDOR}/archivos_lms/${actividad.archivo}`} target="_blank" rel="noopener noreferrer">
                        {actividad.archivo}
                    </a>
                )}
                {actividad.id_tipo === 4 && (
                    <>
                        {isHTML(actividad.contenido) ? (
                            <div dangerouslySetInnerHTML={{ __html: actividad.contenido }}></div>
                        ) : (
                            <div>{actividad.contenido}</div>
                        )}
                    </>
                )}
                {actividad.id_tipo === 5 && (
                    <Button onClick={() => { navigate(`foro_estudiante`, { state: { actividad: actividad, IdUsuario } }); }}>Ingresar al foro</Button>
                )}
                {actividad.id_tipo === 6 && (
                    <>
                        {estudiante && (
                            <Button onClick={() => { navigate(`actividad`, { state: { actividad: actividad, IdUsuario } }); }}>Ver más</Button>
                        )}
                    </>
                )}
                {actividad.id_tipo === 7 && (
                    <>
                        {estudiante && (
                            <Button onClick={() => { navigate(`actividad`, { state: { actividad: actividad, IdUsuario } }); }}>Ver más</Button>
                        )}
                    </>
                )}
            </Card>
        </>
    );
}

