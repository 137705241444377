import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { BASE_SERVIDOR } from '../../services/api/config'

export default function RevisionTarea() {
    const respuesta =useLocation().state.respuesta
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Revision de Tarea</Card.Title>
                </Col>
                <Col>
                    Nota: {respuesta.nota} de 100
                </Col>
            </Row>
        </Card>
        <Card>
            <Row>
                <Col>
                    <b>Fecha:</b> {respuesta.created_at.substring(0,10)}
                </Col>
                <Col>
                    <b>Adjunto:</b> <a target='_blank' href={`${BASE_SERVIDOR}/adjuntos_lms/${respuesta.adjunto}`}>{respuesta.adjunto}</a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Mensaje:</b> {respuesta.mensaje_estudiante}
                </Col>
                <Col>
                    <b>Respuesta:</b> {respuesta.mensaje_docente}
                </Col>
            </Row>
        </Card>
    </>
  )
}
