import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { FormService } from '../../services/api/formService'
import { LocalStorage } from '../../services';
import { BASE_SERVIDOR } from '../../services/api/config';
import { alerta } from '../../helpers/alertas';

export default function PresentarTarea() {
    const api= new FormService();
    const id_usuario = new LocalStorage().getAsJsonItem('usuario').usuario.IdUsuario
    const actividad = useLocation().state.actividad
    const [adjunto, setAdjunto] = useState('')
    const [mensaje, setMensaje] = useState('')

    // enviar tarea
    const enviarTarea = async() => {
        const data={
            adjunto: adjunto,
            id_actividad_curso_lms: actividad.id,
            id_usuario: id_usuario,
            mensaje_estudiante: mensaje,
            id_tipo:actividad.id_tipo
        }
        const response = await api.createWithFormData('resultadosAtividadesLMS', data)
        if(response.success) {
            alerta('Tarea enviada')
            setAdjunto('')
            setMensaje('')
            // retrosar a la lista de tareas
            window.history.back()
        } else {
            alerta(response.message)
        }
    }
  return (
        <div 
            style={{textAlign: 'center', padding: '10px'}}
        >
            <h5
                style={{ textAlign: 'center', padding: '10px'}}
            >Enviar Tarea</h5>
            <Form 
                onSubmit={(e) => {
                    e.preventDefault()
                    enviarTarea()
                }}
            >
                {/* adjuntar archivo */}
                <Form.Group
                    className="mb-3"
                    controlId="formFile"
                >
                    <Row>
                        <Col 
                            sm={3}
                        >
                            <Form.Label>Adjuntar archivo</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control 
                                onChange={(e) => setAdjunto(e.target.files[0])} 
                                type="file" 
                            />
                        </Col>
                    </Row>
                    
                    
                </Form.Group>
                {/* mensaje del estudiante */}
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                >
                    <Row>
                        <Col
                            sm={3}
                        >
                            <Form.Label>Mensaje</Form.Label>
                        </Col>
                        <Col>   
                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={(e) => setMensaje(e.target.value)}
                                value={mensaje}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <div
                    style={{ textAlign: 'right', padding: '10px'}}
                >
                    <Button variant="primary" type="submit">
                        Enviar
                    </Button>
                </div>
            </Form>
        </div>
  )
}
