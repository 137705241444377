import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import GeneradoContenido from '../../LMS/Componentes/Secciones'
import { FormService } from '../../../services/api/formService'
import { useLocation } from 'react-router-dom'
import { LocalStorage } from '../../../services'
import { alerta } from '../../../helpers/alertas'

export default function AulaVirtual() {
    const api=new FormService()
    const [secciones, setSecciones] = useState([])
    const local= new LocalStorage();
    const malla=useLocation().state.malla
    const [progreso, setProgreso] = useState(0)
    const id_usuario=local.getAsJsonItem('usuario').usuario.IdUsuario

    const consultarSecciones=async()=>{
        const secciones=await api.getAll(`seccionesCursosLMS?IdCurso=${malla.id_curso_lms}&id_usuario=${id_usuario}`)
        setSecciones(secciones)
        let catidad_actividades = 0
        let catidad_vistas = 0
        secciones.forEach(seccion => {
            catidad_actividades += seccion.actividades.length
            seccion.actividades.forEach(actividad => {
                if(actividad?.progreso_usuario?.visto==1){
                    catidad_vistas++
                }
            })
        })
        let progreso = (catidad_vistas / catidad_actividades) * 100
        console.log(progreso)
        setProgreso(progreso.toFixed(2))
        // calcularProgreso()
    }
    useEffect(()=>{
        consultarSecciones()
    },[])

    const modificarProgreso = async(actividad,estado) => {
        const data={
            id_recurso_actividad_lms:actividad.id,
            id_usuario:id_usuario,
            visto:estado
        }
        const response= await api.create('cursosLMS/modificarProgreso',data)
        if(response.success){
          setProgreso(response.data)
          consultarSecciones()
        }else{
          alerta(response.message)
        }       
    }

    // calcular el progreso de las actividades, estas estan dentro de las secciones

    const calcularProgreso = () => {
       let catidad_actividades = 0
       let catidad_vistas = 0
       secciones.forEach(seccion => {
           catidad_actividades += seccion.actividades.length
           seccion.actividades.forEach(actividad => {
               if(actividad?.progreso_usuario?.visto==1){
                   catidad_vistas++
               }
           })
       })
       let progreso = (catidad_vistas / catidad_actividades) * 100
       console.log(progreso)
        setProgreso(progreso.toFixed(2))
    }



  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Aula Virtual - {malla.nombre_curso_lms}</Card.Title>
                    <p>{malla.descripcion_curso_lms}</p>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <p
                        style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            backgroundColor: "#005aa6",
                            textAlign: "center",
                            color: "#fff",
                            lineHeight: "50px",
                            display: "inline-block",
                        }}
                    >
                        {progreso} %
                    </p>
                    <p>Porcentaje visto</p>
                    
                </Col>
            </Row>
            
        </Card>
        {secciones.map((seccion, index) => (
            <GeneradoContenido 
                index={index} 
                seccion={seccion}
                estudiante={true}
                modificarProgreso={modificarProgreso}
            />
        ))
        
        }
    </>
  )
}
