import React from 'react'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { InputFile } from '../../inputFile/inputFile'
import { Button } from '../../button/button'
import { Message } from '../../Message/Message'
import { CardPay } from '../../cardPay/CardPay'
import BarraProgreso from '../../BarraProgreso/BarraProgreso'

import './DocumentUpload.css'
import { useEffect } from 'react'
import { useState } from 'react'
import { ProgressBar } from 'react-bootstrap'

const ESTADO_DOCUMENTO = {
    NO_SE_HA_CARGADO_NADA:'0',
    APROBADO:'1',
    RECHAZADO:'2'
}

const DocumentUpload = ({
    registerPay,
    isloadingPay,
    loadingFull,
    onPay,
    urlBill,
    documents,
    onHandleSubmit,
    error,
    handleChange,
    loading,
    oferta,
}) => {
    const [porcentaje,setPorcetaje]=useState(0.0);

    function filtrar(obj) {
        if (obj.requisito!=null) {
          return true;
        } else {
          return false;
        }
    }
    useEffect(()=>{
        calcularPorcentaje();
    })

    const calcularPorcentaje=()=>{
        const requicitosTotales=documents.length
        const requcitosAprobados=documents.filter(filtrar).length
        if(requicitosTotales>0){
            let porcentaje=requcitosAprobados*100/requicitosTotales
            let porcentaje_decimal=Number.parseFloat(porcentaje).toFixed(2);
            setPorcetaje(porcentaje_decimal)
        }
    }
    
    return (
        <>
        <ProgressBar  variant="success" now={porcentaje} label={`${porcentaje}%`} />
            <div className='d-flex justify-content-around'> 
                {registerPay?.Descripcion&&                     
                    <CardPay
                        title={'Inscripción'}
                        price={registerPay?.Valor}
                        description={registerPay?.Descripcion}
                        isDownload
                    >
                        <Button
                            className={'p-3'}
                            type={'button send'}
                        >
                            <a 
                                style={{color:'white'}} 
                                href={ urlBill } 
                                download
                            >
                                Descargar Instructivo 
                            </a> 
                        </Button>
                    </CardPay>                        
                }
            </div>
            {
                loadingFull
                ? <LoadingSpinner /> 
                : !!documents && documents.map((document, key) => (
                    <form 
                        onSubmit={ e => onHandleSubmit(e, document) } 
                        className='estudiante-documento'
                        key={ key }
                    >
                        <div className='estudiante-documento__document'>
                            <h6 className='estudiante-documento__document-title'>
                                { document.Nombre }
                            </h6>
                            <p>Adjunte un documento PDF de máximo 10 Mb</p>
                            { document?.requisito?.EstadoRequisito == undefined && 
                                oferta.EstadoRequisitos =="0" &&
                                    <section className='estudiante-documento__update-document'>
                                        <InputFile 
                                            label={ document.Descripcion }
                                            name='Archivo'
                                            accept='image/*,.pdf'
                                            onChange={ handleChange }
                                        />
                                        <Button 
                                            type='send'
                                            loading={ loading }
                                        >
                                            Subir
                                        </Button>
                                    </section>                                
                            }
                            {document?.requisito?.EstadoRequisito == ESTADO_DOCUMENTO.NO_SE_HA_CARGADO_NADA &&
                            oferta.EstadoRequisitos =="0" &&
                                    <>
                                    <div className="alert alert-warning" role="alert">
                                        Su documento está en proceso de revisión.
                                    </div>
                                        <section 
                                            className='estudiante-documento__update-document'
                                        >
                                            <InputFile 
                                                label={ document.Descripcion }
                                                name='Archivo'
                                                accept='.pdf'
                                                onChange={ handleChange }
                                            />
                                            <Button 
                                                type='send'
                                                loading={ loading }
                                            >
                                                Actualizar documento
                                            </Button>
                                        </section>
                                    </>
                            }
                            {document?.requisito?.EstadoRequisito == "1" && 
                                    <>
                                        <Message type='ok'>
                                            Su documento fue aprobado.
                                        </Message>
                                    </> 
                            }
                            { document?.requisito?.EstadoRequisito == "2" &&
                            // oferta.EstadoRequisitos =="0" &&
                                    <>
                                        <Message type='error'>
                                            Su documento fue rechazado.
                                        </Message>
                                        <h6>Sucedio por el siguiente motivo:</h6>
                                        <p>
                                            { document.requisito.Observacion }
                                        </p>
                                        {oferta.EstadoRequisitos =="0"&&
                                            <section className='estudiante-documento__update-document'>
                                                <InputFile 
                                                    label={ document.Descripcion }
                                                    name='Archivo'
                                                    accept='.pdf'
                                                    onChange={ handleChange }
                                                />
                                                <Button 
                                                    type='send'
                                                    loading={ loading }
                                                >
                                                    Actualizar documento
                                                </Button>
                                            </section>
                                        }
                                        
                                    </>
                            }
                        </div>
                    </form>
                ))
            }
            {
                error && (
                    <Message type='error'>
                        { error?.message }
                    </Message>
                )            
            }
        </>
    )
}

export { DocumentUpload }