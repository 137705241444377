import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'
import { useFetchGet } from '../../hooks/useFetchGet'
import FormsAR from './FormsAR'
import Secciones from './Componentes/Secciones'
import { useLocation, useNavigate } from 'react-router-dom'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Swal from 'sweetalert2'



const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: '100%',
});

export default function ConfigurarCurso() {
  const navigate=useNavigate()
  const api=new FormService()
  const curso=useLocation().state.curso
  const [modalSeccion, setModalSeccion] = useState(false)
  const [dataSeccion, setDataSeccion] = useState({})
  const [secciones, setSecciones] = useState([])
  const [seccionActual, setSeccionActual] = useState({})
  const [modalAR, setModalAR] = useState(false)
  const [dataAR, setDataAR] = useState({})
  const [actulizar, setActulizar] = useState(false)
  const[tiposAR]=useFetchGet("actividadesCursosLMS/tipos")
  const[modoActualizar, setModoActualizar]=useState(false)


  // consultar los momentos evaluativos de la asignatura
  const[momentosEvaluativos]=useFetchGet(`notasAsignatura?IdUnidadAsignatura=${curso.id_malla}&IdOferta=${curso.id_oferta}`)

  const consultarSecciones=async()=>{
    const response=await api.getAll(`seccionesCursosLMS?IdCurso=${curso.id}`)
    setSecciones(response)
  }

  useEffect(()=>{
    consultarSecciones()
  },[])

  const eliminar = async(actividad) => {
      const respose=await api.eliminarDefinitivo(`recursoActividadLMS`,actividad.id)
      if(respose.success){
        alerta('Elemento eliminado correctamente')
        consultarSecciones() 
      }else{
        alerta('No se pudo eliminar el Elemento','error')
      }
  }



  const crearSeccion=async()=>{
    const data={
      ...dataSeccion,
      id_curso:curso.id
    }
    let response;
    if(seccionActual){
      response=await api.update("seccionesCursosLMS",data,seccionActual.id)
    }else{
      response=await api.create("seccionesCursosLMS",data)
    }
   
    if(response.success){
      setModalSeccion(false)
      consultarSecciones()
      alerta("Acción realizada con exito")
    }else{
      alerta("No se pudo crear la seccion","error")
    }
  }

  const crearAR=async()=>{
    const data={
      ...dataAR,
      id_tipo:dataAR.tipo.id,
      id_seccion:seccionActual.id
    }
    if(dataAR.RequiereCalificacion){
      if(!dataAR.id_nota_asignatura){
        return alerta("Debe seleccionar el momento evaluativo","error")
      }
    }

    let response;
    if(!modoActualizar){
       response=await api.createWithFormData("recursoActividadLMS",data)
    }else{
       response=await api.updateWithFormData(`recursoActividadLMS`,dataAR,dataAR.id)
    }
   
      if(response.success){
        setModalAR(false)
        setDataAR({})
        consultarSecciones()
      }else{
        alerta("No se pudo crear la actividad","error")
      }
  }

  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    } 
    const items = reorder( secciones, result.source.index, result.destination.index );
    setSecciones(items);

 
    const updatedSecciones = items.map((seccion, index) => ({
      id: seccion.id,
      orden: index + 1
    }));

    const data={
      secciones:updatedSecciones,
      id_curso:curso.id
    }

    const response = api.create('seccionesCursosLMS/ordenar', data);
    if(response.success){
      console.log('secciones actualizadas')
      consultarSecciones()
    }else{
      console.log('no se pudo actualizar las secciones')
    }
  };

  // eliminar sección
  const eliminarSeccion = async(id) => {
    // primero confirmar si se puede eliminar
    const result = await Swal.fire({
      title: '¿Estas seguro de eliminar esta sección?',
      text: 'Al hacerlo también se eliminarán los elementos que contiene',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar',
    });
  
    if (result.isConfirmed) {
      console.log('si se puede eliminar')
      const response = await api.eliminarDefinitivo(`seccionesCursosLMS`,id);
      if (response.success) {
        alerta('Elemento eliminado correctamente');
      } else {
        alerta('No se pudo eliminar el Elemento', 'error');
      }
    } else {
      console.log('cancelar')
    }
  }
  
  return (
    <>
        <Card>
          <Row>
            <Col sm={8}>
              <Card.Body>
                  <Card.Title>{curso?.nombre}</Card.Title>
                  <p>{curso?.descripcion}</p>
              </Card.Body>
            </Col>
            <Col
              style={{gap:'10px',display:'flex',alignItems:'center',alignContent:'end'}}
            >
              <Button
                onClick={() => navigate('estudiantes',{state:{curso}})}
              >
                Ver Estudiantes
              </Button>
              <Button
                onClick={() => {
                  setModalSeccion(true)
                  setDataSeccion({})
                  setSeccionActual(null)
                }}
              >
                  Crear Sección
              </Button>
            </Col>
          </Row>
        </Card>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {secciones.map((seccion, index) => (
                <Draggable key={seccion.id} draggableId={String(seccion.id)} index={index}>
                  {(provided, snapshot) => (
                      <Secciones
                        provided={provided}
                        seccion={seccion}
                        index={index}
                        setModalAR={setModalAR}
                        setSeccionActual={setSeccionActual}
                        modalAR={modalAR}
                        setDataAR={setDataAR}
                        eliminar={eliminar}
                        setModoActualizar={setModoActualizar}
                        setSecciones={setSecciones} // Añade esta línea
                        secciones={secciones}
                        setModalSeccion={setModalSeccion}
                        setDataSeccion={setDataSeccion}
                        eliminarSeccion={eliminarSeccion}

                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Modal
        show={modalSeccion}
        onHide={() => setModalSeccion(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{seccionActual ? 'Actualizar Sección' : 'Crear Sección'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={dataSeccion?.nombre}
                onChange={(e) => setDataSeccion({ ...dataSeccion, nombre: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as={"textarea"}
                value={dataSeccion?.descripcion}
                onChange={(e) => setDataSeccion({ ...dataSeccion, descripcion: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalSeccion(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => crearSeccion()}>
            {seccionActual ? 'Actualizar' : 'Crear'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* moda para crear una activdiad o recurso */}
      <Modal
        show={modalAR}
        onHide={() => setModalAR(false)}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear Actividad o Recurso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px',alignItems:'center' }} >
                <h4>Recursos</h4>
                {tiposAR&& tiposAR.filter(tipo=>tipo.tipo=="Recurso").map((tipo,i)=>{
                  return(
                    <Form.Label key={i}
                      style={{ 
                        border: '1px solid #cccc', 
                        padding: '5px', 
                        cursor: 'pointer',
                        textAlign: 'center',
                        borderRadius: '5px',
                       }}
                    >{tipo.nombre}
                      <Form.Check
                        key={i}
                        type="radio"
                        checked={dataAR?.tipo?.id==tipo?.id}
                        name="tipo"
                        value={tipo.id}
                        onChange={(e) => setDataAR({ ...dataAR, tipo: tiposAR.filter(tipo=>tipo.id==e.target.value)[0] })}
                      />
                    </Form.Label>
                  )
                })}
              </div>
              <div
                style={{ display: 'flex', flexWrap: 'wrap', gap: '10px',alignItems:'center' }}
              >
                <h4>Actividades</h4>
                {tiposAR.filter(tipo=>tipo.tipo=="Actividad").map((tipo,i)=>{
                  return(
                    <Form.Label key={i}
                      style={{ 
                        border: '1px solid #cccc', 
                        padding: '5px', 
                        cursor: 'pointer',
                        textAlign: 'center',
                        borderRadius: '5px',
                       }}
                    >{tipo.nombre}
                      <Form.Check
                        key={i}
                        type="radio"
                        name="tipo"
                        value={tipo.id}
                        checked={dataAR?.tipo?.id==tipo?.id}
                        onChange={(e) => setDataAR({ ...dataAR, tipo: tiposAR.filter(tipo=>tipo.id==e.target.value)[0] })}
                      />
                    </Form.Label>
                  )
                })}
              </div>
              <hr/>
              {dataAR?.tipo &&
                  <FormsAR
                    momentosEvaluativos={momentosEvaluativos}
                    dataAR={dataAR}
                    setDataAR={setDataAR}
                    tipo={dataAR?.tipo}
                />
              }              
            </Form.Group>
          </Form> 
        </Modal.Body>
        <Modal.Footer>
          <>
              <Button variant="secondary" onClick={() => setModalAR(false)}>
                Cancelar
              </Button>
              
              <Button variant="primary" onClick={() => crearAR()}>
                {modoActualizar?"Actualizar":"Crear"}
              </Button>
           </>
        </Modal.Footer>
      </Modal>
    </>
  )
}
