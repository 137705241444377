import React from "react";
import { Card, Button, DropdownButton, Dropdown, Col, Row, Image } from "react-bootstrap";
import { BASE_SERVIDOR, BASE_URL, BASE_URL_REPOSITORIO } from "../services/api/config";

function CardAsignaturas({ notas, onVerPlandeTrabajo, onSeeAsistencia, onVerDetalles, setMalla, setModalCancelar, navigate }) {
  const handleAulaVirtualClick = (event, element) => {
    event.stopPropagation();
    navigate(`aula_virtual`, { state: { malla: element } });
  };
  const imagen = (element) => {
    const url = element.imagen?BASE_SERVIDOR+"/archivos_lms/img/"+ element.imagen:"/img/img_asignatura.jpg"
    return url
  }
  return (
    <>
      <Row className="mt-3">
        {notas.map((element, index) => (
          <Col key={index} xs={12} sm={6} className="mb-3">
            <div onClick={() => { navigate(`aula_virtual`, { state: { malla: element } }); }} style={{ cursor: "pointer" }}>
              <Card key={index} className="mb-3">
                <div style={{ height: "200px", overflow: "hidden" }}>
                  <Card.Img src={imagen(element)} style={{ objectFit: "cover", height: "100%", width: "100%" }} />
                </div>
                <Card.Body>
                  <div className="d-flex justify-content-end">
                    <DropdownButton 
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                    title="Acciones" variant="secondary" onClick={(e) => e.stopPropagation()}>
                      {element?.IdDimension == 1 &&
                        <Dropdown.Item onClick={() => onVerPlandeTrabajo(element)}>
                          <i className="bi bi-activity" /> Plan de Trabajo
                        </Dropdown.Item>
                      }
                      <Dropdown.Item onClick={() => onSeeAsistencia(element)}>
                        <i className="bi bi-calendar3" /> Asistencias
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => onVerDetalles(element)}>
                        <i className="bi bi-eye" /> Notas
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        setMalla(element);
                        setModalCancelar(true);
                      }}>
                        <i className="bi bi-x-lg" /> Cancelar
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(event) => handleAulaVirtualClick(event, element)}>
                        <i className="bi bi-laptop" /> Aula Virtual
                      </Dropdown.Item>
                    </DropdownButton>

                  </div>
                  <Card.Title>{element.asignatura}</Card.Title>
                  <Card.Text>
                    <strong>Docente:</strong> {element?.Docente}
                    {/* <Row>
                        <Col>
                            <strong>Tipo:</strong> {element?.TipoAsignatura}
                        </Col>
                        <Col>
                            <strong>Creditos:</strong> {element?.Creditos}
                        </Col>
                        <Col>
                            <strong>Horas:</strong> {element?.horas}
                        </Col>
                    </Row> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default CardAsignaturas;
