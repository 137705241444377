import React from 'react'
import { BsCardText, BsChatRightText, BsCloudUploadFill, BsFileEarmarkPdf, BsLink45Deg, BsListTask, BsYoutube } from 'react-icons/bs';


export default function IconoRecursoActividad({id_tipo }) {

  return (
    <>
        {id_tipo==1&&<BsYoutube /> } 
        {id_tipo==2&& <BsLink45Deg /> } 
        {id_tipo==3&& <BsFileEarmarkPdf/> } 
        {id_tipo==4&& <BsCardText /> } 
        {id_tipo==5&& <BsChatRightText /> } 
        {id_tipo==6&& <BsListTask /> } 
        {id_tipo==7&& <BsCloudUploadFill /> } 
        {' '}
    </>
  )
}
