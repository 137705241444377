import React, { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { BsChatRightText } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { alerta } from '../../../helpers/alertas'

export default function ForoEstudiante() {
    const api= new FormService();
    const actividad = useLocation().state.actividad
    const [temas, setTemas] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        consultarParticipantes()
    },[])

    const consultarParticipantes = async () => {
        const response = await api.getAll(`temasForoLMS?id_actividad_curso_lms=${actividad.id}`)
        if (response.success) {
            setTemas(response.data)
            console.log(response.data);
        }else{
            alerta(response.message)
        }
    }
  return (
    <>
        <Card>
            <Card.Title> <BsChatRightText/> {actividad.nombre}</Card.Title>
        </Card>
        <Card>
            <Card.Title> Instrucciones</Card.Title>
            {actividad.descripcion}
        </Card>
        <div
            style={{
                display: 'flex',
                justifyContent: 'end',
                padding: '10px',
            }}
        >
            <Button
                onClick={() => navigate('participar', { state: { actividad: actividad, }, }) }
            > Añadir Nuevo Tema </Button>
        </div>
        <Card>
            <Card.Title>Temas de Foro </Card.Title>

            <Table> 
              <thead>
                    <tr>
                        <th>Tema</th>
                        <th>Nombre</th>                        
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    {temas.map((tema) => (
                        <tr>
                            <td>
                            <a href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    navigate('tema_foro', { state: { tema: tema,actividad:actividad }, }) 
                                }}
                            >
                                {tema.tema}
                            </a></td>
                            <td>{tema.nombre}</td>
                            <td>{tema.created_at.substring(0,10)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

        </Card>
    </>
  )
}
