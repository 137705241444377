import React, { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { FormService } from '../../services/api/formService'
import { useLocation } from 'react-router-dom';
import { alerta } from '../../helpers/alertas';
import { BASE_SERVIDOR } from '../../services/api/config';

export default function CalificarTarea() {
    const api= new FormService();
    const respuesta = useLocation().state.respuesta
    const [respuestaDocente, setRespuestaDocente] = useState(respuesta.mensaje_docente)
    const [nota, setNota] = useState(respuesta.nota)
    

    const enviarCalificacion = async () => {
        const data = {
            id:respuesta.id,
            mensaje_docente:respuestaDocente,
            nota:nota
        }
        // verifica que tenga una nota y que este entre el rango de calificación
        if (nota == null || nota < 0 || nota > 100) {
            alerta("La calificación debe ser un valor entre 0 y 100")
            return
        }
        const response = await api.create("resultadosAtividadesLMS/calificarTarea",data)
        if (response.success) {
            alerta("Respuesta guardada")
            window.history.replaceState(null, '', window.location.href);
            window.history.back()
        }else{
            alerta(response.message)
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <b>Fecha:</b> {respuesta.created_at.substring(0,10)}
                </Col>
                <Col>
                    <b>Adjunto:</b> <a target='_blank' href={`${BASE_SERVIDOR}/adjuntos_lms/${respuesta.adjunto}`}>{respuesta.adjunto}</a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Mensaje:</b> {respuesta.mensaje_estudiante}
                </Col>
                <Col>
                    <b>Respuesta:</b> {respuesta.mensaje_docente}
                </Col>
            </Row>
        </Card>

        <Card>
            <Card.Title
                style={{textAlign: 'center', padding: '10px'}}
            >
                Calificar Tarea
            </Card.Title>
        <Form
            onSubmit={async (e) => {
                e.preventDefault();
                enviarCalificacion();
            }}
        >
            <Form.Group controlId="exampleForm.ControlInput1">
                <Row 
                    style={{justifyContent: 'center', padding: '10px'}}
                >
                    <Col sm={3}>
                        <Form.Label>Calificación</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control 
                            value={nota} onChange={(e) => setNota(e.target.value)} 
                            type="number" 
                            min={0} 
                            max={100}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Row 
                    style={{justifyContent: 'center', padding: '10px'}}
                >
                    <Col sm={3}>
                        <Form.Label>Respuesta </Form.Label>
                    </Col>
                    <Col>
                    <Form.Control 
                        value={respuestaDocente}
                        onChange={(e) => setRespuestaDocente(e.target.value)} 
                        as="textarea" rows={3} 
                    />
                    </Col>
                </Row>
            </Form.Group>
            <Row
                style={{textAlign: 'center', padding: '10px'}}
            >
                <Col>
                    <Button variant="primary" type="submit">
                        Enviar Calificación
                    </Button>
                </Col>
            </Row>
        </Form>
        </Card>
    </>
  )
}
