import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Col, ToggleButton, ToggleButtonGroup, Row, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {MdDelete} from 'react-icons/md';
import { FormService } from '../../../services/api/formService';
import { alerta } from '../../../helpers/alertas';
import { useEffect } from 'react';
import MostrarPreguntas from './MostrarPreguntas';
import PrevisulizarPreguntas from './PrevisulizarPreguntas';

const ConfigurarCuestionario = () => {
    const cuestionario = useLocation().state.cuestionario;
    const api=new FormService();
    const [showModal, setShowModal] = useState(false);
    const [descripcion, setDescripcion] = useState('');
    const [tipoPregunta, setTipoPregunta] = useState('opcion_multiple');
    const [opciones, setOpciones] = useState('');
    const [opcionesArray, setOpcionesArray] = useState([]);
    const [respuestaCorrecta, setRespuestaCorrecta] = useState('');
    const [respuestaCorta, setRespuestaCorta] = useState('');
    const [esVerdadero, setEsVerdadero] = useState(false);
    const [preguntas, setPreguntas] = useState([]);

    const handleCrearPregunta = async () => {
        const data={
            texto: descripcion,
            tipo: tipoPregunta,
            id_cuestionario: cuestionario.id
        }
        if (tipoPregunta === 'opcion_multiple') {
            // data.opciones = opcionesArray;
            // envialo como json
            data.opciones = JSON.stringify(opcionesArray);
            data.opcion_correcta = respuestaCorrecta;
        } else if (tipoPregunta === 'respuesta_corta') {
            data.respuesta_corta = respuestaCorta;
        } else if (tipoPregunta === 'verdadero_falso') {
            data.es_verdadero = esVerdadero;
        }

        const response=await api.create("preguntasLMS",data);
        if(response.success){
            setShowModal(false);
            consultarPreguntas();
        }else{
            alerta("No se pudo crear la pregunta","error");
        }
           
    };


    const handleAgregarOpcion = () => {
        if (opciones.trim() !== '') {
            setOpcionesArray([...opcionesArray, opciones.trim()]);
            setOpciones('');
        }
    };

    const marcarCorrecta = (opcion) => {
        setRespuestaCorrecta(opcion);
    };

    const handleEliminarOpcion = (index) => {
        setOpcionesArray(opcionesArray.filter((_, i) => i !== index));
    };

    const consultarPreguntas=async()=>{
        const response=await api.getAll(`preguntasLMS?IdCuestionario=${cuestionario.id}`);
        setPreguntas(response);
    }

    useEffect(()=>{
        consultarPreguntas();
    },[])

    return (
        <div>
            <Card>
                <Row>
                    <Col>
                        <Card.Title>{cuestionario?.titulo}</Card.Title>
                        <p>Configurar el cuestionario con las preguntas</p>
                    </Col>
                    <Col
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button variant="primary" onClick={setShowModal}>
                            Crear Pregunta
                        </Button>
                    </Col>
                </Row>
            </Card>
            <PrevisulizarPreguntas
                preguntas={preguntas}
            />

            <Modal show={showModal} onHide={()=>setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Crear Pregunta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formDescripcion">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control as="textarea" rows={3} value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Tipo de Pregunta</Form.Label>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <Form.Label
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Form.Check
                                        type="radio"
                                        name="tipoPregunta"
                                        value="opcion_multiple"
                                        checked={tipoPregunta === 'opcion_multiple'}
                                        onChange={(e) => setTipoPregunta(e.target.value)}
                                    />
                                    Opción Múltiple
                                </Form.Label>
                                <Form.Label
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Form.Check
                                        type="radio"
                                        name="tipoPregunta"
                                        value="respuesta_corta"
                                        checked={tipoPregunta === 'respuesta_corta'}
                                        onChange={(e) => setTipoPregunta(e.target.value)}
                                    />
                                    Respuesta Corta
                                </Form.Label>
                                <Form.Label
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Form.Check
                                        type="radio"
                                        name="elige"
                                        value="verdadero_falso"
                                        checked={tipoPregunta === 'verdadero_falso'}
                                        onChange={(e) => setTipoPregunta(e.target.value)}
                                    />
                                    Verdadero o Falso
                                </Form.Label>
                            </div>
                        </Form.Group>

                        {tipoPregunta === 'opcion_multiple' && (
                            <div>
                                <Form.Group controlId="formOpciones">
                                    <Row>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nueva opción"
                                                value={opciones}
                                                onChange={(e) => setOpciones(e.target.value)}
                                            />
                                        </Col>
                                        <Col>
                                            <Button variant="primary" onClick={handleAgregarOpcion}>
                                                Agregar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <br></br>
                                {opcionesArray.length > 0 && (
                                    <div>
                                        <ul className="list-group">
                                            {opcionesArray.map((opcion, index) => (
                                            <li
                                                    key={index}
                                                    className={`list-group-item ${
                                                        respuestaCorrecta === opcion ? 'bg-success text-white' : ''
                                                    }`}
                                                    onClick={() => marcarCorrecta(opcion)}
                                                >
                                                    {opcion}

                                                    <span
                                                        style={{
                                                            float: 'right',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleEliminarOpcion(index)}
                                                    >
                                                        <MdDelete />
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}

                        {tipoPregunta === 'respuesta_corta' && (
                            <Form.Group controlId="formRespuestaCorta">
                                <Form.Label>Respuesta Corta</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese la respuesta corta"
                                    value={respuestaCorta}
                                    onChange={(e) => setRespuestaCorta(e.target.value)}
                                />
                            </Form.Group>
                        )}

                        {tipoPregunta === 'verdadero_falso' && (
                            <Form.Group controlId="formEsVerdadero">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Es Verdadero"
                                    checked={esVerdadero}
                                    onChange={() => setEsVerdadero(!esVerdadero)}
                                />
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShowModal(false)}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleCrearPregunta}>
                        Crear Pregunta
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ConfigurarCuestionario;
