import React, {useEffect, useState } from 'react'
import MostrarPreguntas from './MostrarPreguntas'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { useLocation } from 'react-router-dom'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { FormService } from '../../../services/api/formService'
import { LocalStorage } from '../../../services'

export default function CuestionarioEstudiante() {
    const api= new FormService();
    const id_usuario= new LocalStorage().getAsJsonItem('usuario').usuario.IdUsuario
    const actividad = useLocation().state.actividad
    const [preguntas]=useFetchGet(`preguntasLMS?IdCuestionario=${actividad.id_actividad}`);


  return (
    <div
        style={{ paddingBottom: '50px'}}
    >
      <Card>
            <Row>
                <Col>
                    <Card.Title>
                    {actividad.nombre}
                    </Card.Title>
                    <p>{actividad.descripcion?actividad.descripcion:'.....'}</p>
                </Col>
                <Col>
                Numero de intentos: {actividad.numero_intentos}
                </Col>
            </Row>
            <Row>
                <Col>
                    Fecha de apertura: {actividad.fecha_inicio.substring(0,10)}
                </Col>
                <Col>
                    Fecha de cierre: {actividad.fecha_fin.substring(0,10)}
                </Col>
            </Row>
        </Card>
      
        <div
          style={{ textAlign: 'center', padding: '10px'}}
        >
          <Button variant="primary">Iniciar Intento</Button>
        </div>
        
    </div>
  )
}
