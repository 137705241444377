import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormService } from '../../services/api/formService';
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'

export default function EstudiantesCurso() {

  const navigate = useNavigate();
  const id=useLocation().state.curso.id
  const endPoint=`cursosLMS/estudiantes?id_curso=${id}`

  return (
    <>
    <Card>
        <Card.Title>Estudiantes</Card.Title>
    </Card>
    <DynamicTable
        endPoint={endPoint}
        titleColumn={["Id","Documento","Nombre","Acciones"]}
        attributes={["NumeroDocumento","Nombre"]}
        // showIndexColumn={false}
        onDelete={false}
        onUpdate={false}
        handleOptionClick={(e)=>navigate(`progreso`,{state:{estudiante:e,id_curso:id}})}
        labelOptionClick={"Progreso"}
    />  
    </>
  )
}
