import React from 'react'
import { FormSignUp } from '../../components/FormSignUp/FormSignUp'
import { Arrow } from '../../components/arrow/Arrow'
import '../../components/FormSignUp/FormSignUp.css'
import { Link } from 'react-router-dom'



const SignUp = ({
    handleSubmit,
    handleChangeConfirmPassword,
    confirmPassword,
    handleChange,
    loading,
    formData,
    list,
    noMatch,
    checkMayorEdad,
    setCheckMayorEdad,
    navigate_habeas
}) => {
    const Logo="img/logo-halcon-azul.png"

    return (
        <>
            <header>
                <nav className="headerHome">
                    <figure className="headerHome__logo" style={{float:'left'}}>
                        <img src={"/"+Logo} width="90" alt="Logo aeronautica" />
                    </figure>
                    {/* <div id='logo-header-incio'>
                        <div style={{marginTop:0}}>Centro de Estudios Aeronáuticos </div>
                        <center>
                            <div>Institución Universitaria</div>
                        </center>
                    </div>   */}

                    {/* <div className="link_portafolio">
                        <Link className="text-dark" to="../oferta">
                            <i class="bi bi-book me-2"></i>Portafolio Académico
                        </Link>
                    </div> */}
                </nav>
            </header>
            <div>
                <Arrow />
            </div>
            <main>
                <h1 className='text-center text-bold p-5'>
                    Formulario de Registro al Sistema de Información Académico Halcón ERP
                </h1>
                <div className='text-habeas'>
                <p>“Al realizar el registro manifiesta bajo la gravedad del juramento que la información suministrada en el presente formato de registro es verídica, autoriza la confirmación de la misma, en caso de ser necesario.” Ley 1581 del 2012</p>
                </div> 
                <FormSignUp 
                    handleSubmit={ handleSubmit }
                    handleChangeConfirmPassword={ handleChangeConfirmPassword }
                    confirmPassword={ confirmPassword }
                    handleChange={ handleChange }
                    loading={ loading }
                    formData={ formData }
                    list={ list }
                    noMatch={ noMatch }
                    checkMayorEdad={ checkMayorEdad }
                    setCheckMayorEdad={ setCheckMayorEdad }
                    navigate_habeas={navigate_habeas}
                />
            </main>
            <div style={{
                        textAlign:"right",
                        fontSize:"15px",
                        color:"#ccc",
                        position:"fixed",
                        bottom:"20px",
                        right:"20px"
                        }}
            >
                Vigilada Mineducación
            </div>
        </>
    )
}

export { SignUp }