import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {NavbarHeader } from '../../components/dashboard/NavbarHeader/NavbarHeader';
import {
    useNavigate,
    Outlet,
    useLocation,
  } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { clear, setCurrentMenu, setIdRol, setStateDimensiones, setUser } from '../Login/UserSlice';
import ApiDashboard from "../../services/api/dashboard";
import { LocalStorage } from "../../services";
import { Modal } from '../../components/modal/Modal'
import { Sidebar } from "../../components/sidebar/Sidebar";
import { MenuList } from "../../components/dashboard/MenuList/MenuList";
import { FormService } from "../../services/api/formService";
import { Button } from "../../components/button/button";

import './Dashboard.css';
import { Message } from "../../components/Message/Message";
import { data } from "../../components/outlet/DashboardHome/rolesHomePages/admin/AdminDashboardHome";

const Dashboard = () => {

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const lStorage = new LocalStorage
  const [menu, setMenu] = useState([]);
  const [error, setError] = useState(null);
  const [isOpenChangeRol, setIsOpenChangeRol] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [roles, setRoles] = useState([])
  const [width, setWidth] = useState(window.innerWidth)
  window.onresize = () => {
    setWidth(window.innerWidth)
  }
    
  useEffect(() => {
    const usuario = lStorage.getAsJsonItem('usuario')
    const menu = lStorage.getAsJsonItem('menu')
    const authority = lStorage.getAsJsonItem('roles')
    setRoles(authority)
    if (!lStorage.getAsJsonItem('currentRol')) {
      lStorage.setAsStringItem('currentRol', authority[0])
    }
    dispatch(setUser(usuario))
    setMenu(menu)
  }, [])

  useEffect(() => {
    if (width > 768) setIsOpen(false)
  }, [width])

  const handleChangeSidebar = () => {
    setIsOpen(!isOpen)
  }

  const handleChangeRol = async (rol) => {
    const formService = new FormService()

    try {
      // verifcar si el roles 4 y redirigir a dashboard de estudiante
      if (rol.IdRol === 4) {
        Navigate("estudiante/mis_cursos_activos")
      }else{
        Navigate("inicio")
      }
      

      const response = await formService.getAll(`menu2?IdRol=${rol.IdRol}`)
      lStorage.removeItem('menu')
      lStorage.removeItem('currentRol')
      lStorage.setAsStringItem('menu', response.menu)
      lStorage.setAsStringItem('currentRol', rol)
      setMenu(response.menu)
      setIsOpenChangeRol(!isOpenChangeRol)
      dispatch(setCurrentMenu(response.menu))
    } catch (err) {
      setError(err)
    }
  }

  const handleChangeModal = () => {
    setIsOpenChangeRol(!isOpenChangeRol)
  }

  return (
    <div className="dashboard">
      <header>
        <NavbarHeader 
          handleChangeSidebar={ handleChangeSidebar }
          handleChangeModal={ handleChangeModal }
        />
      </header>
      <main className='dashboard__main'>
        <div className='dashboard__nav'>
          <Sidebar 
            isOpen={ isOpen } 
            close={ handleChangeSidebar }
          >
            {
              width <= 768 && (
                <MenuList 
                  menu={ menu }
                  handleChangeSidebar={ handleChangeSidebar }
                />
              )
            }
          </Sidebar>
          {
            width > 768 && (
              <MenuList 
                menu={ menu }
              />
            )
          }
        </div>
        {/* <div style={{
          textAlign:"right",
          fontSize:"15px",
          color:"#ccc",
          position:"fixed",
          bottom:"20px",
          right:"20px"
          }}>Vigilada Mineducación</div> */}
        <div className='dashboard__container'>
          {
            error && (
              <Message type='ok'>
                { error.message }
              </Message>
            )
          }
          <Outlet/>
        </div>
      </main>
      <Modal 
        isOpen={ isOpenChangeRol }
        close={ handleChangeModal }
      >
        <h4 className="text-center mb-4">Cambio de Rol</h4>
        <div className="d-flex flex-column">
          {
            roles.map((rol, index) => (
              <Button
                key={ index }
                type='light'
                className='navbar-dashboard__rol'
                onClick={ () => handleChangeRol(rol) }
              >
                { rol.Descripcion }
              </Button>
            ))
          }
        </div>
      </Modal>
    </div>
  )
}
export { Dashboard }