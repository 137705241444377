import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Cronometro = ({ fechaCreacion, minutos, onTiempoAgotado }) => {
  const tiempoTotal = minutos * 60 * 1000;
  const [tiempoRestante, setTiempoRestante] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (fechaCreacion) {
      const fechaInicio = new Date(fechaCreacion.replace(' ', 'T'));
      const intervalo = setInterval(() => {
        const ahora = new Date();
        const tiempoTranscurrido = ahora.getTime() - fechaInicio.getTime();
        const tiempoRestante = tiempoTotal - tiempoTranscurrido;
        setTiempoRestante(tiempoRestante);

        if (tiempoRestante <= 0) {
          clearInterval(intervalo);
          if (onTiempoAgotado) {
            onTiempoAgotado();
          } else {
            navigate(-1);
          }
        }
      }, 1000);

      return () => clearInterval(intervalo);
    }
  }, [fechaCreacion, navigate, onTiempoAgotado, tiempoTotal]);

  const segundos = Math.floor((tiempoRestante / 1000) % 60);
  const minutosRestantes = Math.floor((tiempoRestante / (1000 * 60)) % 60);
  const horas = Math.floor(tiempoRestante / (1000 * 60 * 60));

  return (
    <div>
      {tiempoRestante > 0 ? (
        <h3>{horas.toString().padStart(2, '0')}:{minutosRestantes.toString().padStart(2, '0')}:{segundos.toString().padStart(2, '0')}</h3>
      ) : (
        <p>Tiempo agotado</p>
      )}
    </div>
  );
};

export default Cronometro;