import React, { useEffect, useState } from 'react'
// import { FormContainer } from '../../../componentsForm/formContainer/FormContainer';
// import { AddButton } from '../../../componentsForm/addButton/addButton';
import { useSelector } from 'react-redux';
import { AdminDashboardHome } from './rolesHomePages/admin/AdminDashboardHome';
import { EstudianteDashboardHome } from './rolesHomePages/estudiante/EstudianteDashboardHome';
import { SecretarioDashboardHome } from './rolesHomePages/SecretarioDashboardHome';
import { DocenteDashboardHome } from './rolesHomePages/docente/DocenteDashboardHome';
import { LocalStorage } from '../../../services';
import { SinRol } from './rolesHomePages/sinRol/SinRol';

import './Home.css'
import { useNavigate } from 'react-router-dom';

function DashboardHome (){
    const ls = new LocalStorage()
    const navigate = useNavigate()
    const roles = ls.getAsJsonItem('roles') || []
    const currentIdRol = ls.getAsJsonItem('currentRol')?.IdRol || null
    const imagen_bienvenida="/img/bienvenida.png"
    const bienvenida_ies='/img/bienvenida_ies.jpg'
    const dimension=ls.getItem('dimension')

    useEffect(()=>{
      if(currentIdRol=='4'){
        // navigate('/estudiante/mis_cursos_activos')
      }
 
      
    },[currentIdRol])
  
  return (<>
    <div style={{width:'100%',}}>
      {dimension=='"true"'?
      <img style={{width:'100%'}} src={bienvenida_ies} alt="Imagen bienvenida" />
      :
      <img style={{width:'100%'}} src={imagen_bienvenida} alt="Imagen bienvenida" />
      }
    </div>

</>)
}
export { DashboardHome }