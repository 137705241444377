import React from 'react'
import { Tab } from 'react-bootstrap';
import { OfferCard } from '../../components/offerCard/OfferCard';
import FormatosMen from '../../modules/componentesC/Formatos/FormatosMen';

export default function Cursos({
    cursos,
    eventKey,
    onClickInscripcion,
    IdDimension
}) {

  return (
    <Tab.Pane eventKey={eventKey}>
        <div
            className=""
            id="pills-IES"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
            >
                <div className="d-flex flex-wrap justify-content-evenly">
                    {!!cursos &&
                        cursos.map((item, key) => {
                            return (
                                <OfferCard
                                    key={key}
                                    program={item}
                                    onClickInscripcion={onClickInscripcion}
                                    // programLink={() => onClickInscripcion( item ) }
                                />
                            );
                        })}
                    {cursos.length == 0 ? (
                        <p>No existen resultados</p>
                    ) : null}
                </div>
            </div>
    </Tab.Pane>
  )
}
