import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { AsignaturaEstudiantes } from './CursosActivos/AsignaturaEstudiantes'
import { CursosActivos } from './CursosActivos/CursosActivos'
import { Asistencias } from './shared/components/Asistencias'
import { Notas } from './shared/components/Notas'
import { AutoEvaluacion } from './AutoEvaluacion/AutoEvaluacion'
import { CalendarioAsignatura } from './calendario/CalendarioAsignaturas'
import { CalendarioDocente } from './calendario/CalendarioDocente'
import { CursosFinalizados } from './CursosFinalizados/CursosFinalizados'
import { ListadoAsignaturas } from './shared/components/ListadoAsignaturas'
import { ListadoEvaluaciones } from './AutoEvaluacion/ListadoEvaluaciones'
import { MomentosEvaluativos } from './shared/components/MomentosEvaluativos'
import { Alertas } from './Alertas'
import ConsultarCarga  from './carga/ConsultarCarga'
import { SeguiminetoAlerta } from '../bienestar/SeguiminetoAlerta'
import { Carga } from './carga/Carga'
import { ListadoHeteroevaluacion } from './Heteroevaluacion/ListadoHeteroevaluacion'
import { PlaneacionAcademica } from './CursosActivos/PlaneacionAcademica'
import { PlaneacionAcademicaMicro } from './CursosActivos/PlaneacionAcademicaMicro'
import PlanTrabajo from './PlanTrabajo'
import MomentosAsistencia from './MomentosAsistencia'
import { PlaneacionAsignatura } from './CursosActivos/PlaneacionAsignatura'
import PeridosInscritos from './PeriodosInscritos/PeridosInscritos'
import { ApplicantListContainer } from '../../components/outlet/ApplicantList/ApplicantListContainer'
import ExamenesSuficiencia from './ExamenesSuficiencia/ExamenesSuficiencia'
import { ListadoCoevaluaciones } from './Coevaluacion/ListadoCoevaluaciones'
import { Coevaluacion } from './Coevaluacion/Coevaluacion'
import ConfigurarCurso from '../LMS/ConfigurarCurso'
import EstudiantesCurso from '../LMS/EstudiantesCurso'
import ProgresoEstudiante from '../LMS/ProgresoEstudiante'
import ActividadEstudiante from '../LMS/ActividadEstudiante'
import RevisionCuestionario from '../LMS/Cuestionarios/RevisionCuestionario'
import CalificarTarea from '../LMS/CalificarTarea'
import ForoEstudiante from '../LMS/Foro/ForoEstudiante'
import TemaForo from '../LMS/Foro/TemaForo'
import CrearTema from '../LMS/Foro/CrearTema'


function Docente(props) {

    return (
        <>
            <Routes>
                <Route
                    path="calendario_docente"
                    element={<CalendarioAsignatura />}
                ></Route>
                <Route
                    path="calendario_docente/:id/detalle_calendario"
                    element={<CalendarioDocente />}
                ></Route>

                <Route
                    path="cursos_activos"
                    element={<CursosActivos />}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas"
                    element={<ListadoAsignaturas />}
                ></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual" element={<ConfigurarCurso />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes" element={<EstudiantesCurso />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso" element={<ProgresoEstudiante />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso/actividad" element={<ActividadEstudiante docente={true} />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso/actividad/revision_cuestionario" element={<RevisionCuestionario />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso/actividad/calificar_tarea" element={<CalificarTarea />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso/foro_estudiante" element={<ForoEstudiante />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso/foro_estudiante/tema_foro" element={<TemaForo />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/estudiantes/progreso/foro_estudiante/participar" element={<CrearTema />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/foro_estudiante" element={<ForoEstudiante />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/foro_estudiante/participar/" element={<CrearTema />}></Route>

                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/foro_estudiante" element={<ForoEstudiante />}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/aula_virtual/foro_estudiante/tema_foro" element={<TemaForo />}></Route> 

                {/* plan de tabajo */}
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/plan_trabajo" element={<PlanTrabajo/>}></Route>

                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/plan_trabajo/plan_academico_syllabus" element={<PlaneacionAcademica/>}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/plan_trabajo/plan_academico_microcurriculo" element={<PlaneacionAcademicaMicro/>}></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/plan_trabajo/plan_asignatura" element={<PlaneacionAsignatura/>}></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                    element={<AsignaturaEstudiantes />}
                ></Route>
                {/* momentos de asistencia */}
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/momentos_asistencia"
                    element={<MomentosAsistencia/>}
                ></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/momentos_asistencia/:idmomento" element={<Asistencias canEdit={true}/>}></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                    element={<MomentosEvaluativos />}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                    element={<Asistencias canEdit={true} />}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                    element={<Notas canEdit={true} />}
                ></Route>
                <Route
                    path="cursos_finalizados"
                    element={<CursosFinalizados />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas"
                    element={<ListadoAsignaturas />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                    element={<AsignaturaEstudiantes />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                    element={<MomentosEvaluativos />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                    element={<Asistencias canEdit={false} />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                    element={<Notas canEdit={false} />}
                ></Route>

                <Route
                    path="auto_evaluaciones"
                    element={<ListadoEvaluaciones />}
                ></Route>
                <Route
                    path="auto_evaluaciones/:IdAutoEvaluacion"
                    element={<AutoEvaluacion />}
                ></Route>
                <Route
                    path="heteroevaluacion_es"
                    element={<ListadoHeteroevaluacion />}
                ></Route>
                <Route 
                    path='heteroevaluacion_es/:IdAutoEvaluacion'
                    element={<AutoEvaluacion/>}
                ></Route>
                <Route path='alertas' element={<Alertas/>}/>
                <Route path='alertas/seguimiento_alerta' element={<SeguiminetoAlerta/>}/>
                <Route path='carga_docente' element={<ConsultarCarga/>}/>
                <Route path='carga_docente/carga' element={<Carga/>}/>
                <Route path='hojaDe_vida' element={<Carga/>}/>

                <Route path='periodos_inscritos' element={<PeridosInscritos/>} />
                <Route path="periodos_inscritos/:idoferta/asignaturas" element={<ListadoAsignaturas />} />
                <Route path="periodos_inscritos/:idoferta/asignaturas/:idmalla/plan_trabajo" element={<PlanTrabajo/>}></Route>
                <Route path="periodos_inscritos/:idoferta/asignaturas/:idmalla/plan_trabajo/plan_academico_syllabus" element={<PlaneacionAcademica/>}></Route>
                <Route path="periodos_inscritos/:idoferta/asignaturas/:idmalla/plan_trabajo/plan_academico_microcurriculo" element={<PlaneacionAcademicaMicro/>}></Route> 

                <Route path='examenes_suficiencia' element={<ExamenesSuficiencia/>}/>
                {/* coevaluacion */}
                <Route path='coevaluacion' element={<ListadoCoevaluaciones/>}/>
                <Route path='coevaluacion/:idcoevaluacion' element={<Coevaluacion/>}/>
                <Route path="*" element={<DynamicFormContainer />}></Route>
            </Routes>
        </>
    )
}

export { Docente }
