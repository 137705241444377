import React from 'react'
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap'
import { AiOutlineDrag } from 'react-icons/ai';
import ActividadRecurso from './ActividadRecurso';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormService } from '../../../services/api/formService';
import { IoMdSettings } from 'react-icons/io';


  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : null,
    padding: grid,
    width: '100%',
  });

export default function Secciones({
  seccion,
  setModalAR,
  setSeccionActual,
  setDataAR,
  estudiante = false,
  docente = false,
  index = 0,
  modificarProgreso,
  eliminar,
  setModoActualizar,
  setSecciones,
  secciones,
  provided,
  setModalSeccion,
  setDataSeccion,
  eliminarSeccion,
  IdUsuario
}) {

  const api = new FormService();
  console.log(IdUsuario)

  const reorderActividades = (list, startIndex, endIndex) => {
    const result = JSON.parse(JSON.stringify(list));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const onDragEnd = (result) => {
  console.log(result)
    if (!result.destination) {
        return;
    }

    const items = reorderActividades(seccion.actividades, result.source.index, result.destination.index);

    const updatedActividades = items.map((actividad, index) => ({
        ...actividad,
        orden: index + 1
    }));

    const updatedSeccion = {
        ...seccion,
        actividades: updatedActividades
    };

    const updatedSecciones = secciones.map((s) => (s.id === updatedSeccion.id ? updatedSeccion : s));

    setSecciones(updatedSecciones); 

    const actividades = updatedSeccion.actividades.map((a) => ({ id: a.id, orden: a.orden }));

    // actualizar las actividades
    const data={
        'recursos_actividades': actividades,
        'id_seccion': seccion.id
    }
    console.log(data)
    const response = api.create('recursoActividadLMS/ordenar', data)
    if(response.success){
      console.log('secciones actualizadas')
    }else{
      console.log('no se pudo actualizar las secciones')
    }


};

  
  return (
    <>
      <Card
        style={{ marginBottom: "40px" }}
        ref={provided?.innerRef}
        {...provided?.draggableProps}
      >
        <Card.Header
          style={{
            paddingBottom: "20px",
            background: "#005aa6",
            color: "white",
            margin: "-15px",
            marginBottom: "20px",
            minHeight: "100px",
          }}
        >
          <Row>
            <Col sm={9}>
              <Card.Title style={{ color: "white" }} >
                {!estudiante&&                
                  <span {...provided?.dragHandleProps} >
                    <AiOutlineDrag size={25} />
                  </span>
                }
                

                {index + 1} - {seccion?.nombre}
              </Card.Title>
              <p>{seccion?.descripcion}</p>
            </Col>
            <Col
              style={{ textAlign: 'right' }}
            >
              {estudiante ? null :
              <div
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Button
                  onClick={() => {
                    setModalAR(true)
                    setSeccionActual(seccion)
                    setDataAR({})
                    setModoActualizar(false)
                  }}
                >
                  Agregar elemento              
                </Button>
                 <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      border: 'none',
                      background: 'none',
                    }}
                  >
                    <IoMdSettings />
                    <i className="fas fa-ellipsis-v"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setModalSeccion(true)
                        setSeccionActual(seccion)
                        setDataSeccion(seccion)
                      }}
                    >
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        eliminarSeccion(seccion.id)
                      }}
                    >
                      Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </div>
              }
            </Col>
          </Row>
        </Card.Header>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >

                {seccion.actividades.map((actividad, index) => (
                  <Draggable key={actividad.id} draggableId={String(actividad.id)} index={index}>
                    {(provided, snapshot) => (
                      <ActividadRecurso
                        provided={provided}
                        actividad={actividad}
                        estudiante={estudiante}
                        docente={docente}
                        setModalAR={setModalAR}
                        setDataAR={setDataAR}
                        eliminar={eliminar}
                        modificarProgreso={modificarProgreso}
                        setModoActualizar={setModoActualizar}
                        IdUsuario={IdUsuario}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </>
  )
}