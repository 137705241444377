import React from 'react';
import { Card } from 'react-bootstrap';

const RevisionPreguntas = ({ respuestas }) => {
  const convertirOpciones = (opcionesTexto) => {
    return JSON.parse(opcionesTexto);
  };

  const representacionEstudiante = (pregunta, index) => {
    let estiloEnunciado = {};
    if (pregunta.respuestaCorrecta == pregunta.respuestaEstudiante) {
      estiloEnunciado = { color: 'green' };
    }else{
      estiloEnunciado = { color: 'red' };
    }

    switch (pregunta.tipo) {
      case 'opcion_multiple':
        const opciones = convertirOpciones(pregunta.opciones);

        return (
          <div>
            <strong style={estiloEnunciado}>{index + 1} {pregunta.texto}</strong>
            <ul
              style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
              }}
            >
              {opciones.map((opcion, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name={`opcion${pregunta.id}`}
                      disabled
                      checked={opcion === pregunta.respuestaEstudiante} 
                    />
                    {' '}{opcion}
                  </label>
                </li>
              ))}
            </ul>
            <p>Respuesta correcta: {pregunta.respuestaCorrecta}</p>
          </div>
        );

      case 'respuesta_corta':
        return (
          <div>
            <strong style={estiloEnunciado}>{index + 1} {pregunta.texto}</strong><br></br>
            {/* motrar la respuesta en input que no se pueda modificar */}
            <input
              type="text"
              value={pregunta.respuestaEstudiante}
              disabled
            />
            <p>Respuesta correcta: {pregunta.respuestaCorrecta}</p>
          </div>
        );

      case 'verdadero_falso':
        return (
          <div>
            <strong style={estiloEnunciado}>{index + 1} {pregunta.texto}</strong>
            <br></br>
            {/* muestas los radio buttons de verdadero o falso */}
            <input
              type="radio"
              name={`pregunta${pregunta.id}`}
              disabled
              checked={pregunta.respuestaEstudiante === 'verdadero'}
            />
            Verdadero
            <br></br>
            <input
              type="radio"
              name={`pregunta${pregunta.id}`}
              disabled
              checked={pregunta.respuestaEstudiante === 'falso'}
            />
            Falso
            <p>Respuesta correcta: {pregunta.respuestaCorrecta}</p>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {respuestas.map((pregunta, index) => (
        <Card key={pregunta.id}>
          <div>
            {representacionEstudiante(pregunta, index)}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default RevisionPreguntas;
