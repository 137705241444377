
// import { Card, Tab, Tabs, Button, Col, Row } from "react-bootstrap"
// import { useNavigate } from "react-router-dom"
// import { AddButton } from "../../../components/componentsForm/addButton/addButton"
// import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
// import { LocalStorage } from "../../../services"
// import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
// import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
// import { BASE_URL } from "../../../services/api/config";
// import { useFetchGet } from "../../../hooks/useFetchGet"


// function CursosActivosContainer () {
//   const ls = new LocalStorage()
//   const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
//   const navigate = useNavigate()
//   const [data,]=useFetchGet(`estudiante/cursosActivos?IdUsuario=${IdUsuario}`)

//   const handleOptionClick = (item) => {
//     navigate(`${item.IdOferta}/detalle_programa`,{state:{programa:item}})
//   }

//   return (<>
//   <Card>
//       <Row>
//         <Col>
//           <Card.Title>Mis Actividades Académicas Activas</Card.Title>
//         </Col>
//         <Col style={{textAlign:"right", marginRight:10}}>
//           <Button
//               variant='primary'
//               onClick={() => document.querySelector('.programaspdf').click()}
//           >
//               <DowloadFileText
//                   nameFile={"Actividades Académicas Activas.pdf"}
//                   name={"Exportar"}
//                   colortext={"#ffffff"}
//                   icon={<BsFillFilePdfFill />}
//                   classname="programaspdf"
//                   endPoint={`${BASE_URL}/estudiante/cursosActivos/exportar?IdUsuario=${IdUsuario}`}
//               />
//           </Button>
//         </Col>
//       </Row>
//   </Card>
//   {/* <DynamicTable
//     titleColumn={['N°','Nombre','Acción']}
//     attributes = { ['Nombre']}
//     onUpdate={false}
//     onDelete={false}
//     handleOptionClick= {handleOptionClick}
//     labelOptionClick= {'Ver Programa'}
//     classNameOptionIcon={'bi bi-book'}
//     endPoint={`estudiante/cursosActivos?IdUsuario=${IdUsuario}`}
//   ></DynamicTable> */}
//     <Row className="mt-3">
//         {data.map((item, index) => (
//           <Col key={index} xs={12} sm={6}  className="mb-3">
//             <Card>
//               <Card.Body>
//                 <Card.Title>{item.Nombre}</Card.Title>
//                 <Button
//                   variant="primary"
//                   onClick={() => handleOptionClick(item)}
//                 >
//                   Ver Programa
//                 </Button>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//   </>)
// }

// export { CursosActivosContainer }

import React from "react";
import { Card, Button, Col, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../../../components/componentsForm/addButton/addButton";
import { LocalStorage } from "../../../services";
import { BsFillFilePdfFill } from "react-icons/bs";
import DowloadFileText from "../../../components/DowloadFileText/DowloadFileText";
import { BASE_URL, BASE_URL_REPOSITORIO } from "../../../services/api/config";
import { useFetchGet } from "../../../hooks/useFetchGet";

function CursosActivosContainer() {
  const ls = new LocalStorage();
  const IdUsuario = ls.getAsJsonItem("usuario").usuario?.IdUsuario;
  const navigate = useNavigate();
  const [data,] = useFetchGet(`estudiante/cursosActivos?IdUsuario=${IdUsuario}`);

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_programa`, { state: { programa: item } });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title>Mis Actividades Académicas Activas</Card.Title>
            </Col>
            <Col style={{ textAlign: "right", marginRight: 10 }}>
              <Button
                variant="primary"
                onClick={() =>
                  document.querySelector(".programaspdf").click()
                }
              >
                <DowloadFileText
                  nameFile={"Actividades Académicas Activas.pdf"}
                  name={"Exportar"}
                  colortext={"#ffffff"}
                  icon={<BsFillFilePdfFill />}
                  classname="programaspdf"
                  endPoint={`${BASE_URL}/estudiante/cursosActivos/exportar?IdUsuario=${IdUsuario}`}
                />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* 
        <DynamicTable
          titleColumn={['N°','Nombre','Acción']}
          attributes = { ['Nombre']}
          onUpdate={false}
          onDelete={false}
          handleOptionClick= {handleOptionClick}
          labelOptionClick= {'Ver Programa'}
          classNameOptionIcon={'bi bi-book'}
          endPoint={`estudiante/cursosActivos?IdUsuario=${IdUsuario}`}
      ></DynamicTable> 
   */}

      <Row className="mt-3">
        {data.map((item, index) => (
          <Col key={index} xs={12} sm={6} className="mb-3">
            <div onClick={() => handleOptionClick(item)} style={{ cursor: "pointer" }}>
              <Card>
                <div style={{ height: "200px", overflow: "hidden" }}>
                  <Card.Img src={BASE_URL_REPOSITORIO + item.Repositorio} style={{ objectFit: "cover", height: "100%", width: "100%" }} />
                </div>
                <Card.Body>
                  <Card.Title>{item.Nombre}</Card.Title>
                  <Button variant="primary">Ver Programa</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}

export { CursosActivosContainer };
