import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import GeneradoContenido from './Componentes/Secciones'
import { FormService } from '../../services/api/formService'

export default function ProgresoEstudiante() {
    const estudiante = useLocation().state.estudiante
    const api= new FormService();
    const id_curso = useLocation().state.id_curso
    const [secciones, setSecciones] = useState([])

    useEffect(()=>{
        consultarSecciones()
    },[])

    const consultarSecciones=async()=>{
        const response=await api.getAll(`seccionesCursosLMS?IdCurso=${id_curso}`)
        setSecciones(response)
    }

  return (
    <>
        <Card>
            <Card.Title>
                {estudiante.Nombre}
            </Card.Title>
        </Card>
        {secciones.map((seccion, index) => (
            <GeneradoContenido
                seccion={seccion}
                estudiante={true}
                docente={true}
                index={index}
                IdUsuario={estudiante.IdUsuario}
            />
        ))}
    </>
  )
}
