import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

const PrevisulizarPreguntas = ({ preguntas }) => {
  

  const convertirOpciones = (opcionesTexto) => {
    return JSON.parse(opcionesTexto);
  };
  

  const representacionEstudiante = (pregunta, index) => {
    switch (pregunta.tipo) {
      case 'opcion_multiple':
        const opciones = convertirOpciones(pregunta.opciones);

        return (
          <div>
            <strong>{index + 1} {pregunta.texto}</strong>
            <ul
              style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
              }}
            >
              {opciones.map((opcion, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name={`opcion${pregunta.id}`}

                    />
                    {' '}{opcion}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        );

      case 'respuesta_corta':
        return (
          <div>
            <strong>{index + 1} {pregunta.texto}</strong><br></br>
            <input
              type="text"
            />
          </div>
        );

      case 'verdadero_falso':
        return (
          <div>
            <strong>{index + 1} {pregunta.texto}</strong>
            <br></br>
            <label>
              <input
                type="radio"
                name={`verdadero${pregunta.id}`}
                value="verdadero"
              />
              Verdadero
            </label>
            <br></br>
            <label>
              <input
                type="radio"
                name={`verdadero${pregunta.id}`}
                value="falso"
              />
              Falso
            </label>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {preguntas.map((pregunta, index) => (
        <Card key={pregunta.id}>
          <div>
            {representacionEstudiante(pregunta, index)}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default PrevisulizarPreguntas;
