import React, { useEffect, useState } from 'react'
import { FormService } from '../../services/api/formService'

const Select = ({
    id,
    name,
    onChange,
    value,
    label,
    options,
    required=true,
    optionDefault,
    optionValue,
    optionName,
    urlOptionsService,
    secondOptionName,
    tabIndex,
}) => {

    const [optionsViaUrl, setOptionsViaUrl] = useState([])
    const [loadingOptions, setLoadingOptions] = useState(false)

    const fetchOptions= async () => {
        setLoadingOptions(true)
        try {
            const api = new FormService()
            const data = await api.getAll(urlOptionsService)
            if (data?.data){ setOptionsViaUrl(data.data); setLoadingOptions(false) } 
            else {setOptionsViaUrl(data); setLoadingOptions(false);}
            
        } catch (error) { 
            console.log("Error fetching select",urlOptionsService)
            setLoadingOptions(false)
        }
    }
    useEffect(() => {
        if(urlOptionsService){
            fetchOptions()
      }
      
    }, [urlOptionsService])
    

    return (
        <div  className="form-group m-1">
            {
                label && (
                    <label 
                        htmlFor={ id }
                        className="mb-2 mt-3 fw-bold"
                    >
                        { label }
                    </label>
                )
            }
            <select
                id={ id }
                name={ name }
                onChange={ e => onChange(e.target.value, e.target.name) }
                value={ value || '' }
                required ={required ? 'required' : false}
                className="form-control form-select"
                style={{backgroundColor:'#005AA608'}}
                tabIndex={tabIndex}
            >
                <option value="">{loadingOptions?'Cargando...':'Seleccione'} {loadingOptions}</option>
                {/* <option value="" hidden defaultValue>{ optionDefault || 'Seleccione' }</option> */}
                {
                    Array.isArray(options) && (
                        options.map( (item, key) => (
                                <option 
                                    value={ item[optionValue] } 
                                    key={ key }>
                                        { `${item[optionName]} ${item[secondOptionName] || ''}` }
                                </option>
                            )
                        )
                    )
                }
                {
                   !!optionsViaUrl && (
                    optionsViaUrl.map( (item, key) => (
                                <option 
                                    value={ item[optionValue] } 
                                    key={ key }>
                                        { `${item[optionName]} ${item[secondOptionName] || ''}` }
                                </option>
                            )
                        )
                    )
                }
            </select>
        </div>
    )
}

export { Select }