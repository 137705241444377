import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { BsCloudUploadFill, BsListTask } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import RespuestasEstudiante from './Componentes/RespuestasEstudiante'

export default function ActividadEstudiante({docente=false}) {
    const actividad = useLocation().state.actividad
    const estudiante= useLocation().state.estudiante
    const IdUsuario=useLocation().state.IdUsuario
     
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>
                        {actividad.id_tipo==6&& <BsListTask /> } {' '}
                        {actividad.id_tipo==7&& <BsCloudUploadFill /> } {' '}
                        {actividad.nombre}
                    </Card.Title>
                    <p>{actividad.descripcion?actividad.descripcion:'.....'}</p>
                </Col>
                <Col>
                Numero de intentos: {actividad.numero_intentos}
                </Col>
            </Row>
            <Row>
                <Col>
                    Fecha de apertura: {actividad.fecha_inicio.substring(0,10)}
                </Col>
                <Col>
                    Fecha de cierre: {actividad.fecha_fin.substring(0,10)}
                </Col>
            </Row>
        </Card>

        <RespuestasEstudiante
            actividad={actividad}
            docente={docente}
            IdUsuario={IdUsuario}
        />
    </>
  )
}
