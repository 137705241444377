import React, { useEffect, useState } from 'react'
import { FormService } from '../../../services/api/formService';
import { LocalStorage } from '../../../services';
import { Alert, Button, Card, Col, Row, Table } from 'react-bootstrap';
import { BASE_SERVIDOR } from '../../../services/api/config';
import { useNavigate } from 'react-router-dom';

export default function RespuestasEstudiante({actividad,docente,IdUsuario}) {
    const api= new FormService();
    const navigate=useNavigate();
    const [respuestas, setRespuestas] = useState([])
    const [habilitadoIntentos, setHabilitadoIntentos] = useState(false)
    const [habilitadoFecha, setHabilitadoFecha] = useState(false)
    const [intentosAbiertos, setIntentosAbiertos] = useState(null)

    const consultarTareas = async () => {
      const respuestas = await api.getAll(`resultadosAtividadesLMS/resultadoPorUsuario?id_actividad_curso_lms=${actividad.id}&id_usuario=${IdUsuario}`)
      setRespuestas(respuestas)

      if(respuestas.length<actividad.numero_intentos){
        setHabilitadoIntentos(true)
      }else{
        setHabilitadoIntentos(false)
      }
      validarFecha()
      let intentosAbiertos = 0
      respuestas.forEach(respuesta => {
        if (respuesta.estado_respuesta==0 || respuesta.estado_respuesta==null) {
          intentosAbiertos++;
        }
      })

      console.log(intentosAbiertos)

    }
  useEffect(() => {
      consultarTareas()
  },[])

  // validar si la fecha actual esta entre la fecha inicio y la fecha fin de la actividad

  const validarFecha = () => {
    const fechaActual = new Date();
    const fechaInicio = new Date(actividad.fecha_inicio);
    const fechaFin = new Date(actividad.fecha_fin);

    // Ajustar fechaFin a las últimas horas del día
    fechaFin.setHours(23, 59, 59, 999);
  
    if (fechaActual >= fechaInicio && fechaActual <= fechaFin) {
      setHabilitadoFecha(true);
    } else {
      setHabilitadoFecha(false);
    }
  }

  // inciar presentación del cuestionario
  const presentarCuestionario =async (activdiad) => {
    const data={
      id_usuario:IdUsuario,
      id_actividad_curso_lms:activdiad.id
    }
    const response= await api.create("resultadosAtividadesLMS",data)
    if(response.success){
      const resultado=response.data
      navigate(`presentar_cuestionario`,{state:{actividad:activdiad,resultado:resultado}})
    }
  }

  // convetir la fecha a formato dd/mm/yyyy con la hora
  const convertirFecha = (fecha) => {
    const fechaFormateada = new Date(fecha);
    const dia = fechaFormateada.getDate();
    const mes = fechaFormateada.getMonth() + 1;
    const anio = fechaFormateada.getFullYear();
    const hora = fechaFormateada.getHours();
    const minutos = fechaFormateada.getMinutes();
    const segundos = fechaFormateada.getSeconds();
    return `${anio}-${mes}-${dia} ${hora}:${minutos}:${segundos}`
    
  }
 
  return (
    <>
        {actividad.id_tipo==6&&
        <Card style={{ marginTop: '20px'}} >
            <Card.Title style={{textAlign:'center'}}>Intentos realizados</Card.Title>
              <Table>
                <thead>
                  <tr>
                    <th>Fecha de creacion</th>
                    <th>Nota</th>
                    <th>Acciones</th> 
                  </tr>
                </thead>
                <tbody>
                {respuestas.map((respuesta, index) => (
                  <tr key={index}>
                    <td>{convertirFecha(respuesta.created_at)}</td>
                    <td>{respuesta.nota}</td>
                    <td >
                      {respuesta.estado_respuesta==1?
                        <Button 
                          onClick={() => navigate('revision_cuestionario',{state:{id:respuesta.id}})}
                        >
                          Revisión
                      </Button>
                      :
                      <Button
                        variant='success'
                        onClick={() => navigate(`presentar_cuestionario`,{state:{actividad:actividad,resultado:respuesta}})}
                      >
                        Continuar Intento
                      </Button>
                      }
                    </td>
                  </tr>
                ))}
                
                </tbody>
              </Table>
                      
              {/* ocultar botones para el docente */}
              {!docente&&
                <>   
                  {(habilitadoIntentos&&habilitadoFecha)&&
                    <Card>
                      {intentosAbiertos>=0&&
                        <Button
                          onClick={() => presentarCuestionario(actividad)}
                        >
                          Iniciar Intento
                        </Button>
                        }
                    </Card>
                  }
                  {habilitadoFecha==false&&
                    <Alert>
                        Fuera de fecha de presetación
                    </Alert>
                  }
                  {habilitadoIntentos==false&&
                    <Alert>
                        Llegaste al limite de intentos
                    </Alert>
                  }
                </> 
              }
        </Card>
        }

        {actividad.id_tipo==7&&
        <Card>
            <h5 style={{ textAlign: 'center', padding: '10px'}} >Tareas Enviadas</h5>
            {respuestas.map(respuesta => (
                <Table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Nota</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{convertirFecha(respuesta.created_at)}</td>
                            <td>{respuesta.nota}</td>
                            <td>
                              {docente?
                                <Button
                                  onClick={() => navigate('calificar_tarea', {state: {actividad:actividad,respuesta:respuesta}})}
                                >
                                  Calificar Tarea
                                </Button>
                              :
                                <Button
                                  onClick={() => navigate('revision_tarea', {state: {actividad:actividad,respuesta:respuesta}})}
                                >
                                  Revisión
                                </Button>
                              }
                            </td>
                        </tr>
                    </tbody>
                </Table>
        )) } 

        {/* ocultar los botones para el docente */}
        {!docente&&
          <>
          {(habilitadoIntentos&&habilitadoFecha)&&
            <Card>
                <Button 
                  onClick={() => navigate('presentar_tarea', {state: {actividad:actividad}})}
                >
                  Presentar Tarea
                </Button>
            </Card>
          }
          {habilitadoFecha==false&&
            <Alert>
                Fuera de fecha de presetación
            </Alert>
          }
          {habilitadoIntentos==false&&
            <Alert>
                Llegaste al limite de intentos
            </Alert>
          }
          </>
        }

    </Card>
    }         
    </>
  )
}
