import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable"
import { Modal } from "react-bootstrap";
import { Input } from "../../../../components/input/input";
import { Select } from "../../../../components/select/Select";
import {Button, Card} from "react-bootstrap";
import { alerta } from "../../../../helpers/alertas";
import { useState, useEffect } from "react";
import { FormService } from "../../../../services/api/formService";
import { DynamicSearch } from "../../../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { data } from "../../../../components/outlet/DashboardHome/rolesHomePages/admin/AdminDashboardHome";
import { useNavigate } from "react-router-dom";
import { InputFile } from "../../../../components/inputFile/inputFile";
import { BASE_SERVIDOR } from "../../../../services/api/config";

function UsuarioGenerales () {

  const api = new FormService();
  const [loading, setLoading] = useState(false);
  const [camposForm, setCamposForm] = useState();
  const [endPoint, setEndPoint] = useState("usuario");
  const [showDatosUsuario, setShowDatosUsuario] = useState(false);
  const [showCambioAcceso, setShowCambioAcceso] = useState(false);
  const [passNuevo, setPassNuevo] = useState('');
  const [passRepetir, setPassRepetir] =useState('');
  const [tipoNovedad, setTipoNovedad] = useState()
  const [usuario, setUsuario] = useState([]);
  const [datos, setDatos] = useState({});

  const {
    IdUsuario,
    PrimerNombres,
    SegundoNombre,
    PrimerApellidos,
    SegundoApellidos,
    NumeroDocumento,
    IdTipoIdentidad,
    email
  } = datos;

  const [Foto, setFoto] = useState(null);
  const [FotoActual, setFotoActual] = useState(null);

  const navigate = useNavigate();

  const NumText = (string) => {
        var out = '';
        var filtro = 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890-';//Caracteres validos
        
        for (var i=0; i<string.length; i++)
           if (filtro.indexOf(string.charAt(i)) != -1) 
                out += string.charAt(i);

        return out;
  }
  
  const cambiarPass = async()=>{
    /* console.log(usuario) */
    if(passNuevo!='') {
      if(passNuevo.length >= 6) {
        if(passNuevo==passRepetir){
            const data={
                passNuevo:passNuevo,
                IdUsuario:usuario.IdUsuario
            }
            const respuesta=await api.create("administrador/cambiarPass",data)
            console.log(respuesta)
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje,"succes")
                setShowCambioAcceso(false)
            }else{
                alerta("Algo Salio Mal","error")
            }
        } else {
            alerta("Contraseñas diferentes","error")
        }
      } else {
        alerta("Debe Dígitar Mínimo 6 Caracteres", "warning");
      }
    } else {
        alerta("Escribe una contraseña","error")
    } 
  }

  const handleChangeBafore = async (value, name) => {
    
    if(name === "NumeroDocumento") {
       value = NumText(value)
    }

    setDatos({
      ...datos, 
      [name]: value
    });
  }

  const actualizarUsuario = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    /* console.log(datos); */

    const respuesta = await api.updateWithFormData("usuario", {...datos, 'tipoNovedad': tipoNovedad, 'Foto': Foto}, datos.IdUsuario)
    /* console.log(respuesta) */
    if(respuesta.respuesta=="exito"){
        alerta(respuesta.mensaje,"succes")
        setShowCambioAcceso(false)
    }else{
        alerta("Algo Salio Mal", "error")
    }
    setShowDatosUsuario(false)
    setLoading(false);
  }

  const cambiarPassAdministrador = (e) => {
    setShowCambioAcceso(true)
    setUsuario(e)
  };

  const actualizarUsuarioAdministrador = (e, n) => {
    setShowDatosUsuario(true)
    /* setUsuario(e) */
    setDatos(e)
    console.log(e)
    setFotoActual(e.Foto)
  };

  const onSearch = (e) => {
    setEndPoint(`usuario${e}`)
  }

  const fetchCamposFormForFilter = async () => {
    const campos = await api.getAll(`xcampos?id_formulario=116`)
    .then((res)=>{
        setCamposForm(res)
    })
  }

  useEffect(() => {
    fetchCamposFormForFilter()
  }, []);

  return (
    <>
    <Card>
      {(camposForm) &&
        <DynamicSearch onSearch={onSearch} camposForm={camposForm} />
      }
    </Card>
    <DynamicTable
      endPoint={endPoint}
      titleColumn={['N°', 'Email', 'Nombre', 'Apellido', 'N° Documento', 'Teléfono', 'Id Usuario', 'Acciones']}
      attributes={['email', 'PrimerNombres', 'PrimerApellidos', 'NumeroDocumento', 'TelefonoContacto', 'IdUsuario']}
      onDelete={false}
      onUpdate={false}
      onActions={true}
      labelOptionClick="Actualizar Usuario"
      handleOptionClick={actualizarUsuarioAdministrador}
      labelOptionClick2="Cambiar Contraseña"
      handleOptionClick2={cambiarPassAdministrador}
      labelOptionClick3="Auditoria"
      handleOptionClick3={(e)=>navigate(`${e.IdUsuario}/auditoria`,{state:{'usuario':e}})}
    ></DynamicTable>

  <Modal
    show={showDatosUsuario}
    onHide={()=>setShowDatosUsuario(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>
         Actualizar Datos Usuario
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form onSubmit={actualizarUsuario}>
      {/* mostar la foto actual */}
      <div className="row">
        <div className="col-12">
          <img
            src={FotoActual?`${BASE_SERVIDOR}/imgUser/${FotoActual}`:"/img/TempProfile.jpg"}
            alt="profile"
            className="navbar-dashboard__profile-img"
          />
        </div>
      </div>
        <InputFile
          name="Foto" 
          label="Foto"
          completo={true}
          // value={Foto}
          onChange={(e)=>setFoto(e)}
        /> 

        <Input 
            label='Primer Nombre'
            type='text'
            name="PrimerNombres"
            value={ PrimerNombres || '' }
            onChange={handleChangeBafore}
        />
        <Input 
            label='Segundo Nombre'
            type='text'
            name="SegundoNombre"
            value={ SegundoNombre || '' }
            onChange={handleChangeBafore}
            required={false}
        />
        <Input 
            label='Primer Apellido'
            type='text'
            name="PrimerApellidos"
            value={ PrimerApellidos || '' }
            onChange={handleChangeBafore}
        />
        <Input 
            label='Segundo Apellido'
            type='text'
            name="SegundoApellidos"
            value={ SegundoApellidos || '' }
            onChange={handleChangeBafore}
            required={false}
        />
        <Select
          label={'Tipo de Documento'}
          name={'IdTipoIdentidad'}
          value={IdTipoIdentidad}
          onChange={handleChangeBafore}
          optionValue={'IdTipoIdentidad'}
          optionName={'Abr'}
          urlOptionsService={'tipoIdentidad'}
        />
        <Input 
            label='Número Documento'
            type='text'
            name="NumeroDocumento"
            value={ NumeroDocumento || '' }
            onChange={handleChangeBafore}
        />
        <Input 
            label='Email'
            type='text'
            name="email"
            value={ email || '' }
            onChange={handleChangeBafore}
        />
        <Select
          label={'Tipo de Novedad'}
          name={'tipoNovedad'}
          value={tipoNovedad}
          onChange={(e) => setTipoNovedad(e)}
          optionValue={'IdTipoNovedad'}
          optionName={'Nombre'}
          options={[
            {'IdTipoNovedad': 1, 'Nombre': "Nuevo documento/dato del usuario"},
            {'IdTipoNovedad': 2, 'Nombre': "Corregir documento/dato anterior el cual se creó erróneamente"}
          ]}
        />
        <br />
        <div style={{textAlign: "right"}}>
          <Button 
              type='send'
              loading={ loading }
          >
            Actualizar
          </Button>
      </div>
      </form>
    </Modal.Body>
    <Modal.Footer>
      
    </Modal.Footer>
  </Modal>
  
  <Modal
    show={showCambioAcceso}
    onHide={()=>setShowCambioAcceso(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>
         Cambiar Contraseña de Acceso
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Input 
            label='Contraseña Nueva'
            type='password'
            name='SegundoNombre'
            value={ passNuevo || '' }
            onChange={(e)=>setPassNuevo(e) }
        />
        <Input 
            label='Repetir Contraseña'
            type='password'
            name='SegundoNombre'
            value={ passRepetir || '' }
            onChange={(e)=>setPassRepetir(e) }
        />

    </Modal.Body>
    <Modal.Footer>
        <div style={{textAlign: "right"}}>
          <Button
            onClick={cambiarPass}
          >
            Guardar
          </Button>
        </div>
    </Modal.Footer>
  </Modal>
    </>
  )
}

export { UsuarioGenerales };