import React, { useState } from 'react'
import { useEffect } from 'react'
import { Arrow } from '../../components/arrow/Arrow'
import { Card } from '../../components/card/Card'
import { FormService } from '../../services/api/formService'
import { useParams, useLocation } from 'react-router-dom'
import { OfferHeader } from '../../components/OfferHeader/OfferHeader'
import {Button} from 'react-bootstrap'
import FormPreinscripcion from '../../modules/componentesC/FormPreinscripcion/FormPreinscripcion';
import FormInscripcion from '../../modules/componentesC/FormInscripcion/FormInscripcion';
import { LocalStorage } from '../../services'
import { Link } from 'react-router-dom'

import './Offer.css'
import FormatosMen from '../../modules/componentesC/Formatos/FormatosMen'
import { Col, Row } from 'react-bootstrap'

const Offer = () => {

    const [offer, setOffer] = useState({})
    const { idOffer } = useParams()
    const { state } = useLocation()
    const [usuarioLS,setUsuarioLS]=useState()
    const localStorage = new LocalStorage()
    const Logo="img/logo_azul.png"

    const [asignaturas,setAsignaturas]=useState([]);

    const fecthDataOffer = async () => {
        try {
            const formService = new FormService()
            const data = await formService.getAll(`ofertas/fullCurso?IdOferta=${idOffer}`)
            setOffer(data)
        } catch (err) {

        }
        if (localStorage.getItem('usuario') === null) {
            setUsuarioLS(false);
        } else {
            setUsuarioLS(true);
        }
    }

    useEffect(() => {
        console.log(state)
        setAsignaturas(state.asignaturas)
       
        const fecthData = async () => {
            await fecthDataOffer()
        }
        fecthData()
    }, [])

    return (
        <>
        <header>
            <nav className="headerHome">
                {/* <figure className="headerHome__logo" style={{float:'left'}}>
                    <img src={"https://halconerp.com/"+Logo} width="90" alt="Logo aeronautica" />
                </figure> */}
                {/* <div id='logo-header-incio'>
                    <div style={{marginTop:0}}>Centro de Estudios Aeronáuticos </div>
                    <center>
                        <div>Institución Universitaria</div>
                    </center>
                </div>   */}

                {/* <div className="link_portafolio">
                    <Link className="text-dark" to="../oferta">
                        <i class="bi bi-book me-2"></i>Portafolio Académico
                    </Link>
                </div> */}
            </nav>
        </header>
        <div className='offer'>
            <header>
                <Arrow />  
            </header>
            {console.log(state)}
            <main className='offer__container'>
                <OfferHeader 
                    img={ state.Repositorio }
                    offerName={ state.Nombre }
                    offerDescription={ state.Observaciones }
                    horas={state.horas}
                    IdOferta={state.IdOferta}
                    oferta={state}
                />
                <section className='offer__cards'>
                    <Card>
                        <h4 className='text-center mb-5 fw-bold'>
                            Información
                        </h4>
                        <Row>
                            <Col>
                                <h6 className='fw-bold text-secondary'>
                                    Cupos
                                </h6>
                                <h5>
                                    { offer?.Cupos || 0 }
                                </h5>
                            </Col>
                            <Col>
                                <h6 className='fw-bold text-secondary'>
                                    Máximo de cupos
                                </h6>
                                <h5>
                                    { offer?.MaximoEstudiantes || 0 }
                                </h5>
                            </Col>
                            {state?.ValorMatricula &&
                                <Col>
                                    <h6 className='fw-bold text-secondary'>
                                        Valor Matrícula Ordinaria / UVT
                                    </h6>
                                    <h5>
                                        $ {state?.ValorMatricula ? new Intl.NumberFormat('es-CO').format(state?.ValorMatricula) : '' } / {' '}{state.ValorMatriculaUVT}
                                    </h5>
                                </Col>
                            }
                            {state?.ValorInscripcion &&
                                <Col>
                                    <h6 className='fw-bold text-secondary'>
                                        Valor de Inscripción / UVT
                                    </h6>
                                    <h5>
                                        $ {state?.ValorInscripcion ? new Intl.NumberFormat('es-CO').format(state?.ValorInscripcion) : ''} / {state.ValorInscripcionUVT}
                                    </h5>
                                </Col>
                            }
                            
                        </Row>
                    </Card>
                    {/* <Card>
                        <Row>
                            <Col>
                                <h6 className='fw-bold text-secondary'>
                                    Lugar de Desarrollo del Programa
                                </h6>
                                <h5>
                                    { state?.LugarRealizacion || "" }
                                </h5>
                            </Col>
                            <Col>
                                <h6 className='fw-bold text-secondary'>
                                    Correo electrónico para más información
                                </h6>
                                <h5>
                                    <a href={`mailto:${state?.CorreoInformacion || '' }?subject=${'Solicitud de Información de: ' +  state?.Nombre || ''}&body=Hola, es de  mi interes obtener más información acerca de este estudio`}>{ state?.CorreoInformacion || "" }</a>
                                </h5>
                            </Col>
                        </Row>
                    </Card> */}
                    {/* {
                        state.IdDimension==1&& */}
                        <FormatosMen
                            Calendario={state.Calendario}
                            Guia={state.Guia}
                            Resumen={state.Resumen}
                            Archivo={state.Archivo}
                            ArchivoMalla={state.ArchivoMalla}
                        />
                    {/* } */}
                    
                    {/* {asignaturas &&
                        <>
                        <Card>
                        <h4 className='text-center mb-5 fw-bold'>
                            Asignaturas
                        </h4>
                       
                            
                           { !!asignaturas && asignaturas.map(asignatura => (
                                <section className='offer__course'>
                                    <h5 className='mb-4'>
                                        { asignatura.Descripcion }
                                    </h5>
                                    <section className='offer__info'>
                                        <section className='m-2'>
                                            <h6 className='fw-bold text-secondary'>
                                                Intensidad horaria
                                            </h6>
                                            <h5>
                                                { asignatura?.horas || 0}
                                            </h5>
                                        </section>
                                        <section className='m-2'>
                                            <h6 className='fw-bold text-secondary'>
                                                Créditos
                                            </h6>
                                            <h5>
                                                { asignatura?.Creditos || 0}
                                            </h5>
                                        </section>
                                        <section className='m-2'>
                                            <h6 className='fw-bold text-secondary'>
                                                Tipo de Asignatura
                                            </h6>
                                            <h5>
                                                { asignatura?.TipoAsignatura || 0}
                                            </h5>
                                        </section>
                                    </section>
                                </section>
                            ))
                        }
                        </Card>
                        </>
                    } */}
                </section>
                    {/* <div style={{textAlign:"right", paddingTop:15, marginBottom: 40}}>
                        { usuarioLS?
                            <FormInscripcion
                                itemSelected={state}
                            />
                        :
                            <FormPreinscripcion
                                itemSelected={state}
                            />
                        }
                    </div> */}

                    <div style={{
                                textAlign:"right",
                                fontSize:"15px",
                                color:"#ccc",
                                position:"fixed",
                                bottom:"20px",
                                right:"20px"
                                }}
                    >
                        Vigilada Mineducación
                    </div>
            </main>
        </div>
        </>
    )
}

export { Offer }