import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { BsChatRightText } from 'react-icons/bs'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'; 
import { useLocation } from 'react-router-dom'
import { LocalStorage } from '../../../services';
import { alerta } from '../../../helpers/alertas';
import { FormService } from '../../../services/api/formService';

export default function CrearTema() {
    const api= new FormService();
    const actividad = useLocation().state.actividad
    const id_usuario = new LocalStorage().getAsJsonItem('usuario').usuario.IdUsuario
    const[tema, setTema] = useState('')
    const[mensaje, setMensaje] = useState('')

    const enviarParticipacion = async() => {
        const data = {
            tema: tema,
            id_actividad_curso_lms: actividad.id,
            id_usuario: id_usuario,
            contenido: mensaje
        }
        const response = await api.create('temasForoLMS', data)
        if(response.success) {
            alerta('Participación enviada')
            window.history.back()
        } else {
            alerta(response.message)
        }   
    }
    
  return (
    <>
        <Card>
            <Card.Title> <BsChatRightText/> Foro de {actividad.nombre}</Card.Title>
        </Card>
        <Card>
            <Card.Title> Instrucciones</Card.Title>
            {actividad.descripcion}
        </Card>
        <Card>
            <Form 
                onSubmit={(e) => {
                    e.preventDefault()
                    enviarParticipacion()
                }}
            >
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Asunto</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Asunto"
                        value={tema}
                        onChange={(e) => setTema(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >
                    <Form.Label>Mensaje</Form.Label>
                    <ReactQuill 
                        value={mensaje}
                        onChange={(e) => setMensaje(e)}
                        style={{ height: '200px' }}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                ['link', 'image'],
                                ['clean']
                            ],
                        }}
                    />
                </Form.Group>
                <div
                    style={{textAlign: 'right', padding: '10px',paddingTop: '50px'}}
                >   
                    <Button type="submit" variant="primary">Enviar</Button>
                </div>
                
            </Form>
        </Card>

    </>
  )
}
