import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FooterHome } from '../../components/Inicio/FooterHome/FooterHome';
import { Arrow } from '../../components/arrow/Arrow';

import '../inicio/Inicio.css';

import { Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { FormService } from '../../services/api/formService';
import Cursos from './Cursos';
import CursosContinuada from './CursosContinuada';
import { LocalStorage } from '../../services';

const Portafolio = () => {
    const api=new FormService();
    const storage= new LocalStorage();
    const usuario=JSON.parse(storage.getItem("usuario"))
    const [dimensiones, setDimensiones] = useState([]);
    const [nombreDimension, setNombreDimension] = useState('');
    const [usuarioLS, setUsuarioLS] = useState(usuario?true:false);
    const [idUsuario, setIdUsuario] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState('');
    const dimension_actual=storage.getItem('dimension')

    useEffect(() => {
        (async()=>{
            consultarDimensiones()
        })()
    }, []);



    const onClickInscripcion = async (item) => {
        setIsOpenModal(true);
        setItemSelected(item);
    };


    const consultarDimensiones=async()=>{
        const dimensiones=await api.getAll("listadoCursos");
        console.log(dimensiones)
        setDimensiones(dimensiones)
    }

    return (
        <>
            <header className="cursos__header">
                <Arrow color={'bg-white'} texto={usuarioLS?'Regresar al Dashboard':'Regresar al incio'} />
            </header>
            <main
                className="content"
                style={{
                    maxWidth: 1200,
                    margin: '0 auto',
                }}
            >

                <div className="row">
                    <h1> {nombreDimension || ''} </h1>
                </div>
                <div className="row">
                    <h1 className="text-center">Portafolio Académico</h1>
                </div>
                { !usuarioLS &&
                <div className='informacion-ingreso'>
                    <p>
                        <i class="bi bi-exclamation-octagon"></i>
                        <a href='/login'> Inicia sesión </a>
                         o <a href='/sign-up'>regístrese </a>  para incribirse a una oferta
                    </p>
                </div>
                }
                {/* <Tab.Container id="left-tabs-example" defaultActiveKey={dimension_actual=='"true"'?'dim-1':'dim-2'}> */}
                <Tab.Container id="left-tabs-example" defaultActiveKey={'dim-3'}>
                    <div style={{marginBottom:40}}>
                    <Row>

                        <h3>Dimensiones - Actividades Académicas</h3>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                {dimensiones.map((dimension, key)=>
                                    <>
                                        {dimension_actual=='"true"'?
                                        <>
                                            {   dimension.IdDimension==1&&
                                                <Nav.Item key={key} >
                                                    <Nav.Link key={key} eventKey={`dim-${dimension.IdDimension}`}>{dimension.Descripcion}</Nav.Link>
                                                </Nav.Item>
                                            }  
                                        </>                                           
                                        :  
                                            <Nav.Item key={key} >
                                                <Nav.Link key={key} eventKey={`dim-${dimension.IdDimension}`}>{dimension.Descripcion}</Nav.Link>
                                            </Nav.Item>   
                                        }    
                                    </>                        
                                )}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {dimensiones.map((dimension,key)=>
                                    <>
                                        {dimension_actual!='"true"'?
                                        <>
                                            {
                                                dimension.IdDimension==2?
                                                <CursosContinuada 
                                                    key={key} 
                                                    cursos={dimension.cursos}
                                                    eventKey={`dim-${dimension.IdDimension}`}
                                                    onClickInscripcion={onClickInscripcion}
                                                />
                                                :
                                                <>
                                                    <Cursos
                                                        key={key} 
                                                        cursos={dimension.cursos}
                                                        IdDimension={dimension.IdDimension}
                                                        eventKey={`dim-${dimension.IdDimension}`} 
                                                        onClickInscripcion={onClickInscripcion}
                                                    />
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                        {
                                            dimension.IdDimension==1&&
                                            <Cursos
                                                key={key} 
                                                cursos={dimension.cursos}
                                                IdDimension={dimension.IdDimension}
                                                eventKey={`dim-${dimension.IdDimension}`} 
                                                onClickInscripcion={onClickInscripcion}
                                            />
                                        }
                                        </>
                                        }
                                    </>                                                                        
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>                    
                    </div>
                </Tab.Container>                
            </main>
            <footer className="inicio__footer">
                <FooterHome type="dark" />
            </footer>
        </>
    )
}

export default Portafolio;
