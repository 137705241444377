import { useLocation } from 'react-router-dom'
import { Checkbox } from '../../../../components/componentsForm/checkbox/checkbox'
import user from '../../../../assets/img/avatar.jpg'
import { Title } from '../../../../shared/components/title/Title'
import { SubTitle } from '../../../../shared/components/subtitle/SubTitle'
import { Button } from '../../../../components/button/button'
import { useState } from 'react'
import { FormService } from '../../../../services/api/formService'
import { alertaSinBoton } from '../../../../helpers/alertas'
import { TopHeadTable } from '../../../../shared/components/topHeadTable/TopHeadTable'
import { AddButton } from '../../../../components/componentsForm/addButton/addButton'
import { optionsDate } from '../../../../shared/util/date'
import { ICON } from '../../../../constants/icons'
import { Card } from '../../../../components/card/Card'

function Asistencias({ canEdit }) {
    const {
        state: { estudiantes, programa, asignatura, evento },
    } = useLocation()
    const [estudiantesAsistencia, setEstudiantesAsistencia] = useState(
        estudiantes || []
    )

    const onUpdateAsistenciaEstudiante = (value, name, estudiante) => {
        // console.log(estudiantesAsistencia,"------------",typeof value)
        // console.log(name,value,estudiante)
        let updateEstudiante = {
            ...estudiante,
            Asistencia: value === false ? '0' : '1',
        }
        // console.log({updateEstudiante})
        const newEstudianteList = estudiantesAsistencia.map((es) => {
            if (es.IdEstudianteAsistencia === estudiante.IdEstudianteAsistencia)
                return updateEstudiante
            return es
        })
        // console.log({newEstudianteList})
        setEstudiantesAsistencia([...newEstudianteList])
        // setEstudiantesAsistencia((es)=>{
        //   return({
        //     ...estudiantesAsistencia,
        //   })
        // })
    }
    const onSave = () => {
        const api = new FormService()
        const data = estudiantesAsistencia.map((item) => {
            return {
                // IdEstudiante:item.IdEstudiante,
                Asistencia: item.Asistencia,
                IdEstudianteAsistencia: item.IdEstudianteAsistencia,
                // FechaInicio:evento.FechaInicio
            }
        })
        console.log(data)
        // api.create('docente/planillaAsistencia?IdEventoAula=11',data).then(res=>{
        api.create('docente/guardarAsistencias', { asistencias: data }).then(
            (res) => {
                console.log(
                    'se ha guardado con éxito',
                    estudiantesAsistencia,
                    res
                )
                alertaSinBoton('Guardado!', 'success', 'bottom-end', 1500)
            }
        )
    }
    return (
        <>
            <AddButton></AddButton>
            <Card>
                <div className="ml-2">
                    <Title>Asistencias</Title>

                    <TopHeadTable width="95%">
                        <div>
                        {asignatura.Descripcion}
                        </div>                     
                        <div>
                        {new Date(evento.FechaInicio).toLocaleDateString('es-co', optionsDate)} {'  de '}
                        {new Date(evento.FechaInicio).toLocaleTimeString('es-CO')} {' a '} {new Date(evento.FechaFin).toLocaleTimeString('es-CO')}</div>
                    </TopHeadTable>
                    <table style={{ width: '95%' }}>
                        <thead className="thead-student">
                            <tr>
                                <th>
                                    <b>
                                        <h5 style={{ marginLeft: '9px' }}>
                                            Estudiante
                                        </h5>
                                    </b>
                                </th>
                                {/* <th>Asistencia</th> */}
                                <th>
                                    <b>
                                        <h5>Asistió</h5>
                                    </b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!estudiantesAsistencia &&
                                estudiantesAsistencia.map(
                                    (estudiante, index) => (
                                        <tr key={index} className="row-student">
                                            <td>
                                                <div className="content-student">
                                                    <img
                                                        src={
                                                            estudiante?.Foto ||
                                                            user
                                                        }
                                                        alt={`foto estudiante ${estudiante.PrimerNombres} ${estudiante.PrimerApellidos}`}
                                                        className="applicant-list__img-user student"
                                                    />
                                                    <span>
                                                        {
                                                            estudiante.PrimerNombres
                                                        }{' '}
                                                        {
                                                            estudiante.PrimerApellidos
                                                        }
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                {canEdit ? (
                                                    <Checkbox
                                                        // name={estudiante.Asistencia}
                                                        checked={
                                                            estudiante.Asistencia ===
                                                            '0'
                                                                ? false
                                                                : true
                                                        }
                                                        label="Asistió"
                                                        value={
                                                            estudiante.Asistencia ===
                                                            '0'
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(
                                                            value,
                                                            name
                                                        ) =>
                                                            onUpdateAsistenciaEstudiante(
                                                                value,
                                                                name,
                                                                estudiante
                                                            )
                                                        }
                                                    ></Checkbox>
                                                ) : (
                                                    <div>
                                                        {estudiante.Asistencia ===
                                                        '0'
                                                            ? ICON.UNCHECKED
                                                            : ICON.CHECKED}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </table>
                    {canEdit && (
                        <Button
                            type={'send'}
                            onClick={() => {
                                onSave()
                            }}
                        >
                            Guardar
                        </Button>
                    )}
                </div>
            </Card>
        </>
    )
}

export { Asistencias }
