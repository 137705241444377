import React, { useEffect, useState } from 'react'
import MostrarPreguntas from './MostrarPreguntas'
import { FormService } from '../../../services/api/formService'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Cronometro from '../Componentes/Cronometro'
import { alerta } from '../../../helpers/alertas'

export default function PresentarCuestionario() {
    const actividad = useLocation().state.actividad
    const resultado = useLocation().state.resultado
    const api= new FormService()
    const [preguntas, setPreguntas] = useState([])
    const [respuestas, setRespuestas] = useState({});

    useEffect(() => {
        obtenerPreguntas();
    },[])
    
    const obtenerPreguntas = async () => {
        const preguntas = await api.getAll(`preguntasLMS?IdCuestionario=${actividad.id_actividad}`)
        setPreguntas(preguntas)
    }

    // guardar las respuestas
    const guardarRespuestas = async () => {
        const data={
            'respuestas': respuestas
        }
        const response = await api.update(`resultadosAtividadesLMS`,data,resultado.id) 
        if(response.success) {
            alerta("se han guardado las respuestas que usted ha respondido")
        }
    }

    // finalizar el cuestionario
    const finalizarCuestionario = async () => {
        const data={
            'id': resultado.id,
            'respuestas': respuestas,
            'estado': 'finalizado'
        }
        const response = await api.create(`resultadosAtividadesLMS/finalizarCuestionario`, data)

        if(response.success) {
            alerta("Cuestionario finalizado")
            window.history.replaceState(null, '', window.location.href);
            window.history.back();
            
        }else{
            console.log(response.message)
        }
    }

  return (
    <div>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Presentar Cuestionario</Card.Title>
                </Col>
                <Col>
                    <b>Tiempo restante</b>
                    <Cronometro 
                        fechaCreacion={resultado.created_at} 
                        minutos={actividad.minutos_contestar}
                        onTiempoAgotado={finalizarCuestionario}
                    />
                </Col>
            </Row>
        </Card>

        <MostrarPreguntas 
            preguntas={preguntas} 
            respuestas={respuestas}
            setRespuestas={setRespuestas}
        />

        <div style={{display: 'flex',flexDirection: 'row',alignItems: 'end',justifyContent: 'end', gap: '10px'}}>
            <Button 
                onClick={guardarRespuestas} 
                variant="primary"
                >
                Guardar Respuesta
            </Button>
            <Button 
                onClick={finalizarCuestionario}    
                variant="success">
                Finalizar Intento
            </Button>
        </div>
    </div>
  )
}
