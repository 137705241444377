import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { BASE_SERVIDOR, imgDefault } from '../../../services/api/config'
import { BsChatRightText } from 'react-icons/bs'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'; 
import { FormService } from '../../../services/api/formService'
import { alerta } from '../../../helpers/alertas'
import { LocalStorage } from '../../../services'

export default function TemaForo() {
    const api= new FormService();
    const tema = useLocation().state.tema
    const id_usuario = new LocalStorage().getAsJsonItem('usuario').usuario.IdUsuario
    const actividad = useLocation().state.actividad
    const [modal, setModal] = useState(false)
    const [mensaje, setRespuesta] = useState("")
    const [mensajeActual, setMensajeActual] = useState(null)
    const [mensajes, setMensajes] = useState([])
    const [modoEditar, setModoEditar] = useState(false)
    const img=tema.foto?BASE_SERVIDOR+"/imgUser/"+tema.foto:imgDefault

    useEffect(() => {
        consultarMensajes()
    },[])

// envair mensaje
    const enviarMensaje = async() => {
        const data = {
            contenido: mensaje,
            id_usuario:id_usuario,
            id_tema_lms: tema.id
        }
        let response;
        if(modoEditar) {
             response = await api.update('mensajesForoLMS', data, mensajeActual.id)
        }else{
            response = await api.create('mensajesForoLMS', data)
        }
        
        if(response.success) {
            alerta('Respuesta enviada')
            setRespuesta('')
            setModal(false)
            consultarMensajes()
        } else {
            alerta(response.message)
        }
    }
    // consultar mensajes
    const consultarMensajes = async() => {
        const response = await api.getAll(`mensajesForoLMS?id_tema_lms=${tema.id}`)
        if(response.success) {
            setMensajes(response.data)
        } else {
            alerta(response.message)
        }
    }

    // eliminar mensaje
    const eliminarMensaje = async(id) => {
        const respose=await api.eliminarDefinitivo(`mensajesForoLMS`,id)
        if(respose.success){
            alerta('Elemento eliminado correctamente')
            consultarMensajes() 
        }else{
            alerta('No se pudo eliminar el Elemento','error')
        }
    }

  return (
    <>
    <Card>
        <Row>
            <Col>
            <Card.Title>
                <BsChatRightText/> {actividad.nombre} <br/>
                <span
                    style={{color:"#333",paddingLeft:"30px"}}
                >{tema.tema}</span>            
            </Card.Title>
            </Col>
            <Col 
                style={{textAlign:"right"}}
            >
                <Button
                    onClick={() =>{
                        setModal(true)
                        setRespuesta("")
                        setModoEditar(false)
                        setMensajeActual(null)
                    }}
                    variant="outline-primary"
                >
                    Responser
                </Button>
            </Col>
        </Row>
    </Card>
    <div style={{padding: '10px',paddingTop: '0px'}} >
        <Card>
            <Card.Header>
                <div 
                    style={{display:"flex",gap:"10px"}}
                >
                    <div style={{width:"50px",height:"50px",border:"1px solid black",borderRadius:"50%"}} >
                        <img 
                            style={{borderRadius:"50%"}}
                            src={img} 
                            width="50px" 
                            height="50px"                             
                            alt="img"
                        />
                    </div>
                    <div>
                    <div><b>{tema.tema}</b></div>
                    <p>de {tema.nombre} - {tema.created_at.substring(0,10)} </p>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <div dangerouslySetInnerHTML={{__html: tema.contenido}}></div>
                </Card.Text>
            </Card.Body>
        </Card>
        <div 
            style={{paddingLeft:"30px"}}
        >
            {mensajes.map((mensaje, index) => (
                <Card key={index}>
                    <Card.Header>
                        <Row>
                            <Col>
                        <div 
                            style={{display:"flex",gap:"10px"}}
                        >
                            <div style={{width:"50px",height:"50px",border:"1px solid black",borderRadius:"50%"}} >
                                <img 
                                    style={{borderRadius:"50%"}}
                                    src={img} 
                                    width="50px" 
                                    height="50px"                             
                                    alt="img"
                                />
                            </div>
                            <div>
                            <div><b>Re: {tema.tema}</b></div>
                            <p>de {mensaje.nombre} - {mensaje.created_at.substring(0,10)} </p>
                            </div>
                        </div>
                            </Col>
                            <Col
                                style={{textAlign:"right"}}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Acciones
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                setModal(true)
                                                setModoEditar(true)
                                                setRespuesta(mensaje.contenido)
                                                setMensajeActual(mensaje)
                                            }}
                                        >
                                            Editar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                eliminarMensaje(mensaje.id)
                                            }}
                                        >
                                            Eliminar
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>  
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <div dangerouslySetInnerHTML={{__html: mensaje.contenido}}></div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </div>
    </div>

    <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Participar del Tema            
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Mensaje</Form.Label>
                    <ReactQuill 
                        value={mensaje}
                        onChange={(e) => setRespuesta(e)}
                        style={{ height: '200px' }}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                ['link', 'image'],
                                ['clean']
                            ],
                        }}
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={() => setModal(false)}>Cancelar</Button>
            <Button 
            onClick={() => enviarMensaje()}
            >
                {modoEditar ? 'Editar': 'Responder'}
            </Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}
