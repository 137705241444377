import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import ConfigurarCurso from './ConfigurarCurso'
import ConfigurarCuestionario from './Cuestionarios/ConfigurarCuestionario'
import CuestionarioEstudiante from './Cuestionarios/CuestionarioEstudiante'
import EstudiantesCurso from './EstudiantesCurso'
import ProgresoEstudiante from './ProgresoEstudiante'
import ActividadEstudiante from './ActividadEstudiante'
import CalificarTarea from './CalificarTarea'
import RevisionCuestionario from './Cuestionarios/RevisionCuestionario'
import ForoEstudiante from './Foro/ForoEstudiante'
import CrearTema from './Foro/CrearTema'
import TemaForo from './Foro/TemaForo'

export default function LMS() {
    const navigate = useNavigate()
  return (
    <Routes>
        <Route path="cursos" element={
            <DynamicFormContainer 
                idForm="184" 
                labelOptionClick={"Configurar"}
                handleOptionClick={(e) => navigate(`cursos_lms/${e.id}/configurar`,{state:{curso:e}})}
        
            />
        } />
        <Route path="cursos_lms/:id/configurar" element={<ConfigurarCurso />} />
        <Route path="cursos_lms/:id/configurar/estudiantes" element={<EstudiantesCurso />} />
        <Route path="cursos_lms/:id/configurar/estudiantes/progreso" element={<ProgresoEstudiante />} />
        <Route path="cursos_lms/:id/configurar/estudiantes/progreso/actividad" element={<ActividadEstudiante docente={true} />} />
        <Route path="cursos_lms/:id/configurar/estudiantes/progreso/actividad/revision_cuestionario" element={<RevisionCuestionario />} />
        <Route path="cursos_lms/:id/configurar/estudiantes/progreso/actividad/calificar_tarea" element={<CalificarTarea />} />
        <Route path="cursos_lms/:id/configurar/cuestionario" element={<CuestionarioEstudiante />} />
        <Route path="cursos_lms/:id/configurar/foro_estudiante" element={<ForoEstudiante />} />
        <Route path='cursos_lms/:id/configurar/foro_estudiante/participar' element={<CrearTema />} />
        <Route path="cursos_lms/:id/configurar/foro_estudiante/tema_foro" element={<TemaForo />} />
        <Route path="categorias_cursos" element={<DynamicFormContainer idForm="185" />} />
        <Route path="cuestionarios" element={
        <DynamicFormContainer 
          idForm="186" 
          labelOptionClick={"Configurar"}
          handleOptionClick={(e) => navigate(`cuestionarios/${e.id}/configurar`,{state:{cuestionario:e}})}
        />} />
        <Route path="cuestionarios/:id/configurar" element={<ConfigurarCuestionario />} />
        <Route path="*" element={<DynamicFormContainer />} />
    </Routes>
  )
}
