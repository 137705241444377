import React, { useEffect, useState } from 'react';

import { Select } from '../../select/Select';
import { Button } from '../../button/button';
import { ButtonsContainer } from '../../componentsForm/buttonsContainer/buttonsContainer';
import { LeftButtonContainer } from '../../componentsForm/leftButtonContainer/leftButtonContainer';
import { RightButtonContainer } from '../../componentsForm/rightButtonContainer/rightButtonContainer';
import { FormContainer } from '../../componentsForm/formContainer/FormContainer';

import ApiDashboard from '../../../services/api/dashboard';
import { alertaSinBoton, alertaTimer } from '../../../helpers/alertas';
import { Modal } from '../../modal/Modal';
import { Table } from '../../componentsForm/table/Table';
import { Checkbox } from '../../componentsForm/checkbox/checkbox';

// modal type : create-delete-update

const AdmisionDetalle = ({itemSelectedx}) => {
    
    const [codAdmision, setCodAdmision] = useState('');
    const [flagAdmitido, setFlagAdmitido] = useState('');
    const [idConvocatoria, setIdConvocatoria] = useState('');
    const [idDerechosPecuniarios, setIdDerechosPecuniarios] = useState('');
    const [idAspirante, setIdAspirante] = useState('');
    const [idTipoVinculacion, setIdTipoVinculacion] = useState('');
    const [idTipoAdmisionMatricula, setIdTipoAdmisionMatricula] = useState('');


    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [modalType, setModalType] = useState('');
    const endPointLastUrl = 'admisionDetalle'; /// url for service

    //! llamar list */
    const [list, setList] = useState([]);

    //! LLAMAR Y ASIGNAR LIST
    const fecthDataSelect = async () => {
        const apiSelect = new ApiDashboard();
        const convocatoria = await apiSelect.getData('convocatoria');
        const derechoPecuniario = await apiSelect.getData('derechoPecuniario');
        const aspirantes = await apiSelect.getData('aspirantes');
        const tipoVinculacion = await apiSelect.getData('tipoVinculacion');
        const tipoAdmisionMatricula = await apiSelect.getData('tipoAdmisionMatricula');

        if (convocatoria?.status === 'Token is Expired') {
            alertaTimer('La sesion ha expirado', 'info', '4000');
            return false;
        }
        if (derechoPecuniario?.status === 'Token is Expired') {
            alertaTimer('La sesion ha expirado', 'info', '4000');
            return false;
        }
        if (aspirantes?.status === 'Token is Expired') {
            alertaTimer('La sesion ha expirado', 'info', '4000');
            return false;
        }
        if (tipoVinculacion?.status === 'Token is Expired') {
            alertaTimer('La sesion ha expirado', 'info', '4000');
            return false;
        }
        if (tipoAdmisionMatricula?.status === 'Token is Expired') {
            alertaTimer('La sesion ha expirado', 'info', '4000');
            return false;
        }

        setList({
            convocatoria,
            derechoPecuniario,
            aspirantes,
            tipoVinculacion,
            tipoAdmisionMatricula
        });
    };

    //! LOAD_DATA_API */
    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            await fetchDataTable();
            await fecthDataSelect();
            console.log('Itemx SelectedX---->', itemSelectedx);
            setLoading(false);
        };
        fecthData();
    }, []);


    //! FETCH ALL DATA TABLE */
    const fetchDataTable = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.getData(`admisionDetalle?CodAdmision=${itemSelectedx?.CodAdmision}`);
            console.log('RETURN DATATABLE', data);
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
        } catch (e) {
            console.log('#Error fetching dataTable');
            //  setDataTable([])
        }
    };


        //! SUBMIT CREATE OR UPDATE
        const handleSubmit = (e) => {
          e.preventDefault();
          setLoading(true);
          if (modalType === '/create') {
              createService();
          }
          if (modalType === '/update') {
              updateService();
          }
  
          setLoading(false);
  
          // setItemSelected({})
      };

    //! LIMPIAR FORMULARIO
    const cleanFormValues = () => {
        setFlagAdmitido('');
        setIdConvocatoria('');
        setIdDerechosPecuniarios('');
        setIdAspirante('');
        setIdTipoVinculacion('');
        setIdTipoAdmisionMatricula('');
    };

    //! CLICK EVENTS
    const onCreate = () => {
        cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
        console.log('Array Select', list);
    };
    const onDelete = (item) => {
        setItemSelected(item);
        console.log('Item Seleccionado para borrar---->', item);
        setIsOpenModalDelete(true);
        setModalType('/delete');
    };
    const onUpdate = (item) => {
        console.log('update item->', item);
        setItemSelected(item);
        setFlagAdmitido(item.FlagAdmitido);
        setIdConvocatoria(item.IdConvocatoria);
        setIdDerechosPecuniarios(item.IdDerechosPecuniarios);
        setIdAspirante(item.IdAspirante);
        setIdTipoVinculacion(item.IdTipoVinculacion);
        setIdTipoAdmisionMatricula(item.IdTipoAdmisionMatricula);
        setModalType('/update');
        setIsOpenModalUpdate(true);
    };

    //!CLOSE ALL MODALES
    const handleCloseModal = () => {
        setIsOpenModalCreate(false);
        setIsOpenModalUpdate(false);
        setIsOpenModalDelete(false);
    };

    //! SERVICE CREATE
    const createService = async () => {

      let newFlag;
      flagAdmitido === true ? newFlag = 1 : newFlag = 0;

        try {
            const api = new ApiDashboard();
            const data = await api.createData(
                {
                    CodAdmision: itemSelectedx.CodAdmision,
                    FlagAdmitido: newFlag,
                    IdConvocatoria: idConvocatoria,
                    IdDerechosPecuniarios: idDerechosPecuniarios,
                    IdAspirante: idAspirante,
                    IdTipoVinculacion: idTipoVinculacion,
                    IdTipoAdmisionMatricula: idTipoAdmisionMatricula,
                },
                endPointLastUrl
            );
            console.log('data create', data);
            if (!!data.length) await setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesión ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalCreate(false);
            alertaSinBoton(
                'Se ha creado con éxito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            cleanFormValues();
        } catch (e) {}
    };

    //! SERVICE DELETE
    const deleteService = async () => {
        console.log('item a borrar', itemSelected);
        
        try {
            
            const api = new ApiDashboard();
            const data = await api.deleteData(
                {
                    Estado: 0,
                },
                itemSelected.CodAdmisionDetalle,
                endPointLastUrl
            );
            // console.log("data response delete",data,itemSelected.IdPaises)
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                // console.log("data exp",data)
            }
            setIsOpenModalCreate(false);
            handleCloseModal();
            alertaSinBoton(
                'Se ha eliminado con Éxito',
                'success',
                'center',
                '2000'
            );

            fetchDataTable();
            cleanFormValues();
            setItemSelected({});
        } catch (e) {
            console.log('error delete');
        }
    };

    //! SERVICE UPDATE
    const updateService = async () => {

        let newFlag;
        flagAdmitido === true ? newFlag = 1 : newFlag = 0;
        try {
            const api = new ApiDashboard();

            const data = await api.updateData(
                {
                    CodAdmision: itemSelectedx.CodAdmision,
                    FlagAdmitido: newFlag,
                    IdConvocatoria: idConvocatoria,
                    IdDerechosPecuniarios: idDerechosPecuniarios,
                    IdAspirante: idAspirante,
                    IdTipoVinculacion: idTipoVinculacion,
                    IdTipoAdmisionMatricula: idTipoAdmisionMatricula,
                },
                itemSelected.CodAdmisionDetalle,
                endPointLastUrl
            );
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalUpdate(false);
            alertaSinBoton(
                'Se ha actualizado con Éxito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            setItemSelected({});
        } catch (error) {}
    };

    //!FORM
    const Form = () => {
        return (
            <>
                {/* ***FORM CREATE AND UPDATE**** */}
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <Checkbox
                                name='flagAdmitido'
                                value={flagAdmitido}
                                onChange={ setFlagAdmitido }
                            >
                                Admitido
                            </Checkbox>

                            <Select
                                id="IdConvocatoria"
                                name="Nombre"
                                onChange={setIdConvocatoria}
                                value={idConvocatoria}
                                label="Convocatoria"
                                required={true}
                                optionValue="IdConvocatoria"
                                optionName="Convocatoria"
                                options={list.convocatoria}
                            />
                            <Select
                                id="IdDerechosPecuniarios"
                                name="IdDerechosPecuniarios"
                                onChange={setIdDerechosPecuniarios}
                                value={idDerechosPecuniarios}
                                label="Derecho Pecuniario"
                                required={true}
                                optionValue="IdDerechosPecuniarios"
                                optionName="Concepto"
                                options={list.derechoPecuniario}
                            />
                            
                            <Select
                                id="IdAspirante"
                                name="IdAspirante"
                                onChange={setIdAspirante}
                                value={idAspirante}
                                label="Aspirante"
                                required={true}
                                optionValue="IdAspirante"
                                optionName="ApeNombres"
                                options={list.aspirantes}
                            />
                            <Select
                                id="IdTipoVinculacion"
                                name="IdTipoVinculacion"
                                onChange={setIdTipoVinculacion}
                                value={idTipoVinculacion}
                                label="Tipo Vinculación"
                                required={true}
                                optionValue="IdTipoVinculacion"
                                optionName="Descripcion"
                                options={list.tipoVinculacion}
                            />

                            <Select
                                id="IdTipoAdmisionMatricula"
                                name="IdTipoAdmisionMatricula"
                                onChange={setIdTipoAdmisionMatricula}
                                value={idTipoAdmisionMatricula}
                                label="Tipo Admisión Matricula"
                                required={true}
                                optionValue="IdTipoAdmisionMatricula"
                                optionName="Descripcion"
                                options={list.tipoAdmisionMatricula}
                            />

                        </div>
                    </div>
                    <ButtonsContainer>
                        <LeftButtonContainer>
                            <Button type="back" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </LeftButtonContainer>
                        <RightButtonContainer>
                            {modalType === '/update' && (
                                <Button type="send" loading={loading}>
                                    Actualizar
                                </Button>
                            )}

                            {modalType === '/create' && (
                                <Button type="send" loading={loading}>
                                    Crear
                                </Button>
                            )}
                        </RightButtonContainer>
                    </ButtonsContainer>
                </form>
            </>
        );
    };

    return (
        // MAIN CONTAINER
        <FormContainer progress="45" style={{ width: '60%' }}>
            {/* ***CREATE BUTTON**** */}
            <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onCreate}
            >
                Agregar Admisión Detalle
            </button>
            {/* TABLE */}
            <Table
                titleColumn={['Id', 'Aspirante', 'Convocatoria', 'Acción']}
                data={dataTable}
                attributes={[ 'Aspirante', 'Convocatoria',]} //codigo
                onDelete={onDelete}
                onUpdate={onUpdate}
            ></Table>

            {/* ****** MODALES ******  */}

            <Modal isOpen={isOpenModalCreate} close={ handleCloseModal }>{Form()}</Modal>
            <Modal isOpen={isOpenModalUpdate} close={ handleCloseModal }>{Form()}</Modal>

            <Modal isOpen={isOpenModalDelete}>
                <h1 style={{ marginBottom: '4rem' }}>
                    Estas seguro que deseas eliminar "
                    {itemSelected.Descripcion || ''}" ?{' '}
                </h1>
                <button onClick={deleteService} className="btn btn-danger">
                    Si
                </button>
                <button
                    style={{ marginLeft: '2rem' }}
                    onClick={handleCloseModal}
                    className="btn btn-secondary"
                >
                    No
                </button>
            </Modal>
        </FormContainer>
    );
};

export { AdmisionDetalle };
