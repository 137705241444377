import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

const MostrarPreguntas = ({ preguntas,respuestas,setRespuestas }) => {
  

  const convertirOpciones = (opcionesTexto) => {
    return JSON.parse(opcionesTexto);
  };
  
  const handleRespuesta = (preguntaId, respuesta) => {
    setRespuestas({
      ...respuestas,
      [preguntaId]: respuesta,
    });
    console.log(respuestas);
  };

  const representacionEstudiante = (pregunta, index) => {
    switch (pregunta.tipo) {
      case 'opcion_multiple':
        const opciones = convertirOpciones(pregunta.opciones);

        return (
          <div>
            <strong>{index + 1} {pregunta.texto}</strong>
            <ul
              style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
              }}
            >
              {opciones.map((opcion, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name={`opcion${pregunta.id}`}
                      value={opcion}
                      onChange={() => handleRespuesta(pregunta.id, opcion)}
                      checked={respuestas[pregunta.id] === opcion}
                    />
                    {' '}{opcion}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        );

      case 'respuesta_corta':
        return (
          <div>
            <strong>{index + 1} {pregunta.texto}</strong><br></br>
            <input
              type="text"
              onChange={(e) => handleRespuesta(pregunta.id, e.target.value)}
              value={respuestas[pregunta.id] || ''}
            />
          </div>
        );

      case 'verdadero_falso':
        return (
          <div>
            <strong>{index + 1} {pregunta.texto}</strong>
            <br></br>
            <label>
              <input
                type="radio"
                name={`verdadero${pregunta.id}`}
                value="verdadero"
                onChange={() => handleRespuesta(pregunta.id, 'verdadero')}
                checked={respuestas[pregunta.id] === 'verdadero'}
              />
              Verdadero
            </label>
            <br></br>
            <label>
              <input
                type="radio"
                name={`verdadero${pregunta.id}`}
                value="falso"
                onChange={() => handleRespuesta(pregunta.id, 'falso')}
                checked={respuestas[pregunta.id] === 'falso'}
              />
              Falso
            </label>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {preguntas.map((pregunta, index) => (
        <Card key={pregunta.id}>
          <div>
            {representacionEstudiante(pregunta, index)}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default MostrarPreguntas;
